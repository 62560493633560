import React, {useContext, useEffect, useRef, useState} from "react";
import { Formik, Form } from "formik";

import {
  RadioQuestion,
  Button,
  SimulatorResultCard,
  FormWrapper,
} from "~/presentation/views/GTFacil/Simulator/components";
import { SimulatorContext } from "~/presentation/views/GTFacil/Simulator/context/simulator-context";
import { floatToReais } from "~/presentation/utils/reais-parser";
import { reportMapper } from "./reportMapper";

const initialValues = {
  retentionAliquot: {
    label: "4,65%",
    value: 0.0465,
  },
};

export const CsllPisPasepCofinsPJNotSimples = ({ contractorCategory }) => {
  const resultCardRef = useRef();
  const { simulatorValue, setInvalidSimulatorValue, handleCacheReport, cacheReportValues} = useContext(SimulatorContext);

  const calculateResult = (values) =>
    simulatorValue * values.retentionAliquot.value;

  const result = cacheReportValues.length > 0 ? cacheReportValues?.filter(report => report.tax === 2)[0]?.retentionValue : false;

  useEffect(() => {
    if(result) {
      resultCardRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [result]);

  return contractorCategory.inRange(6, 11) ? (
    <p>Não há retenção de CSLL PIS/Pasep e COFINS</p>
  ) : (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values, action) => {
        if (!simulatorValue) {
          setInvalidSimulatorValue(true);
          return;
        }

        action.setSubmitting(true);

        const resultValue = calculateResult(values);

        action.setSubmitting(false);

        const response = reportMapper(values, resultValue);

        handleCacheReport(response);
      }}
    >
      {({ setFieldValue, values }) => (
        <Form>
          <FormWrapper>
            <RadioQuestion
              text="Alíquota da retenção:"
              onChange={(value) => {
                setFieldValue("retentionAliquot", value);
              }}
              firstLabel={values.retentionAliquot.label}
              firstValue={values.retentionAliquot.value}
              value={values.retentionAliquot.value}
              excerpt="A alíquota de retenção da CSLL, da COFINS e da contribuição para o PIS/Pasep é de 4,65%, correspondente à soma das alíquotas de 1%, 3% e 0,65% respectivamente, conforme o art. 31 da lei número 10.833/2003."
              onlyFirstValue
            />

            <Button>Ver Resultado</Button>

            {result ? (
              <SimulatorResultCard
                componentRef={resultCardRef}
                title="Valor da retenção da CSLL, PIS/Pasep e COFINS:"
                value={floatToReais(result)}
                description="O valor da retenção da CSLL, PIS/Pasep e COFINS corresponde ao resultado da multiplicação da alíquota pela base do cálculo."
              />
            ) : null}
          </FormWrapper>
        </Form>
      )}
    </Formik>
  );
};
