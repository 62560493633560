import React from "react";
import { makeItemAnswerUseCases } from "~/main/factories/use-cases/item-answer";
import { makeTypeAnswerUseCases } from "~/main/factories/use-cases/types-answer";
import ItemAnswer from "~/presentation/views/Admin/ItemAnswer/ItemAnswer";

export const makeItemAnswerTable = (params) => (
  <ItemAnswer
    {...params}
    itemAnswerUseCases={makeItemAnswerUseCases()}
    typesAnswerUseCases={makeTypeAnswerUseCases()}
  />
);
