export class Company {
  constructor({
    active,
    address,
    category,
    city,
    cnpj,
    complement,
    email,
    endDate,
    homePhone,
    id,
    mobilePhone,
    name,
    plan,
    startDate,
    dateCompanyRegistered,
    state,
    status,
    totalSubstituteOrDismissed,
    zipCode,
    legalNature,
    contactName,
    contactCpf,
    isECD,
    isExemptionFrom,
    isExemptionAgreement,
    isUnion,
    isEFR,
    cnpjEFR,
    taxClassification,
    codeUG,
    
  }) {
    this.active = active;
    this.address = address;
    this.category = category;
    this.zipCode = zipCode;
    this.city = city;
    this.cnpj = cnpj;
    this.complement = complement;
    this.email = email;
    this.endDate = endDate;
    this.homePhone = homePhone;
    this.id = id;
    this.mobilePhone = mobilePhone;
    this.name = name;
    this.plan = plan;
    this.startDate = startDate;
    this.dateCompanyRegistered = dateCompanyRegistered;
    this.state = state;
    this.status = status;
    this.totalSubstituteOrDismissed = totalSubstituteOrDismissed;
    this.zipCode = zipCode;
    this.legalNature = legalNature;
    this.contactName = contactName;
    this.contactCpf = contactCpf;
    this.isECD = isECD;
    this.isExemptionFrom = isExemptionFrom;
    this.isExemptionAgreement = isExemptionAgreement;
    this.isUnion = isUnion;
    this.isEFR = isEFR;
    this.cnpjEFR = cnpjEFR;
    this.taxClassification = taxClassification;
    this.codeUG = codeUG;
  }
}
