import React, { useContext, useEffect, useRef, useState } from "react";
import { Title, Subtitle } from "../../../../../../components/Layouts/styles";
import { AddCircle, KeyboardArrowDown } from "@mui/icons-material";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import { Box } from "@mui/material";
import RButton from "~/presentation/views/Reinf/components/Common/Buttons/RButton";
import { Grid } from "@mui/material";
import Input from "~/presentation/views/Reinf/components/Common/Inputs";
import { formatNumberToRealString } from "~/presentation/utils/formatCurrency";
import handleMoney from "~/presentation/views/Reinf/components/HandleMoney/handleMoney";
import { Skeleton } from "@mui/material";
import { DialogContext } from "~/presentation/providers/DialogProvider";

export default function INSS({
  item,
  onChangeItem,
  index,
  nfseData,
  disabled,
  serviceClassificationOptions,
  onChangeINSSitem,
  errors,
}) {
  const { setFeedbackSnackbar } = useContext(SnackbarContext);
  const { openDialogBox } = useContext(DialogContext);

  const [showItem, setShowItem] = useState({});

  const newItemsNFseObject = {
    id: 0,
    serviceClassificationId: 0,
    serviceClassification: "",
    bc: 0,
    amount: 0,
    aliquot: 0,
    principalRententionAmount: 0,
    subcontractorAmount: 0,
    annotation: "",
    serviceAmount15: 0,
    serviceAmount20: 0,
    serviceAmount25: 0,
    additionalRetentionAmount: 0,
    additionalNotRetentionAmount: 0,
  };

  function addMore() {
    if (item?.itemsNFse?.length < 9) {
      onChangeItem(
        {
          ...item,
          itemsNFse: [...item.itemsNFse, newItemsNFseObject],
        },
        index,
        null,
        true
      );
    } else {
      setFeedbackSnackbar({
        isOpen: true,
        message: "9 items é o máximo!",
        type: "info",
      });
    }
  }

  function handleDelete(data, index, itemIndex) {
    if (item?.itemsNFse?.length === 1) {
      setFeedbackSnackbar({
        isOpen: true,
        message: "No mínimo tem de haver um item.",
        type: "info",
      });
    } else {
      if (JSON.stringify(data) === JSON.stringify(newItemsNFseObject)) {
        const newItem = {
          ...item,
          itemsNFse: item.itemsNFse.filter((_, i) => i !== itemIndex),
        };
        onChangeItem(newItem, index);
      } else {
        openDialogBox({
          message: `Deseja mesmo excluir este item?`,
          callback: () => {
            const newItem = {
              ...item,
              itemsNFse: item.itemsNFse.filter((_, i) => i !== itemIndex),
            };
            onChangeItem(newItem, index);
          },
        });
      }
    }
  }

  const handleClickItem = (index) => {
    setShowItem((prevState) => ({
      ...prevState,
      [index]: !prevState[index], // Inverte o estado show do item clicado
    }));
  };

  //Error: bcINSS > Valor total da Operação
  const isBcINSSWrong = item.bcINSS > item.amountOperation;
  const isBcINSSWrongText =
    isBcINSSWrong &&
    "O valor da soma das bases de cálculo não pode ser superior ao Valor da Operação.";

  const allBcTypeService = item?.itemsNFse?.reduce((total, curr) => total + curr.bc, 0);
  const allAmountTypeService = item?.itemsNFse?.reduce((total, curr) => total + curr.amount, 0);

  useEffect(() => {
    onChangeItem(
      {
        ...nfseData[index],
        bcINSS: allBcTypeService,
        amountINSS: allAmountTypeService,
      },
      index,
      null,
      true
    );
  }, [allBcTypeService, allAmountTypeService]);

  return (
    <Box sx={styles.container}>
      <Title>Retenção de INSS</Title>

      <Box
        sx={{
          marginBottom: 2,
          paddingInline: 4,
          paddingTop: 2,
          paddingBottom: 2,
          background: "#D9D9D9",
          borderRadius: 4,
        }}
      >
        <Box sx={styles.geralValues.container}>
          <Box sx={styles.geralValues.box}>
            <p style={styles.geralValues.text}>Valor Principal da Base INSS</p>
            <span style={styles.geralValues.value}>{formatNumberToRealString(item?.bcINSS)}</span>
          </Box>
          <Box sx={styles.geralValues.box}>
            <p style={styles.geralValues.text}>Valor Principal da Retenção INSS</p>
            <span style={styles.geralValues.value}>
              {formatNumberToRealString(item?.amountINSS)}
            </span>
          </Box>
          <Box sx={styles.geralValues.box}>
            <p style={styles.geralValues.text}>Alíquota Principal do INSS</p>
            <span style={styles.geralValues.value}>% {item?.aliquotINSS?.toFixed(2)}</span>
          </Box>
        </Box>

        {item?.itemsNFse?.length > 0 ? (
          item?.itemsNFse?.map((data, itemIndex) => {
            const isBcOrAmountZero = data.bc == 0 || data.amount == 0;
            const isAliquotValid = data?.aliquot?.toFixed(2) == item?.aliquotINSS?.toFixed(2);
            const isAliquotWrong =
              !isBcOrAmountZero &&
              !isAliquotValid &&
              `Retenção/Base deve dar ${item?.aliquotINSS}%`;
            return (
              <Box
                sx={{
                  marginBlock: 2,
                  padding: 2,
                  background: "#C9C9C9",
                  borderRadius: 4,
                }}
                key={itemIndex}
              >
                <Grid container spacing={2}>
                  <Grid item xs={3}>
                    <Input
                      type="select"
                      title="Tipo de Serviço"
                      placeholder="Selecione um tipo de serviço"
                      options={serviceClassificationOptions}
                      getOptionLabel={(option) => `${option.id} - ${option.description}`}
                      value={serviceClassificationOptions.find(
                        (item) => item.id === data.serviceClassificationId
                      )}
                      onChange={(option) => {
                        onChangeINSSitem(
                          {
                            ...data,
                            serviceClassificationId: option.id,
                            serviceClassification: option.description,
                          },
                          index,
                          itemIndex,
                          "serviceClassificationId"
                        );
                      }}
                      error={!!errors[`[${index}].itemsNFse[${itemIndex}].serviceClassificationId`]}
                      errorText={
                        errors[`[${index}].itemsNFse[${itemIndex}].serviceClassificationId`]
                      }
                      disabled={disabled}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    {/* O valor da base não pode ser superior ao valor total da operação. */}
                    <Input
                      title="Valor da base de cálculo"
                      type="amount"
                      value={data.bc}
                      onChange={(e) => {
                        const newNumber = handleMoney(e.target.value);
                        onChangeINSSitem(
                          {
                            ...data,
                            bc: newNumber,
                            aliquot: (data.amount / newNumber) * 100,
                          },
                          index,
                          itemIndex,
                          "bc"
                        );
                      }}
                      error={!!errors[`[${index}].itemsNFse[${itemIndex}].bc`] || isBcINSSWrongText}
                      errorText={
                        errors[`[${index}].itemsNFse[${itemIndex}].bc`] || isBcINSSWrongText
                      }
                      disabled={disabled}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Input
                      title="Retenção"
                      type="amount"
                      value={data.amount}
                      onChange={(e) => {
                        const newNumber = handleMoney(e.target.value);
                        onChangeINSSitem(
                          {
                            ...data,
                            amount: newNumber,
                            aliquot: (newNumber / data.bc) * 100,
                          },
                          index,
                          itemIndex,
                          "amount"
                        );
                      }}
                      error={!!errors[`[${index}].itemsNFse[${itemIndex}].amount`]}
                      errorText={errors[`[${index}].itemsNFse[${itemIndex}].amount`]}
                      disabled={disabled}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Input
                      title="Alíquota"
                      type="amount"
                      prefix="%"
                      value={data.aliquot}
                      error={
                        !!errors[`[${index}].itemsNFse[${itemIndex}].aliquot`] || isAliquotWrong
                      }
                      errorText={
                        errors[`[${index}].itemsNFse[${itemIndex}].aliquot`] || isAliquotWrong
                      }
                      disabled
                    />
                  </Grid>
                </Grid>

                <Box sx={{ marginTop: 1 }}>
                  <Subtitle onClick={() => handleClickItem(itemIndex)}>
                    <KeyboardArrowDown
                      style={{
                        fontSize: "2.4rem",
                        rotate: showItem[itemIndex] ? "0deg" : "-90deg",
                        transition: "200ms",
                        color: "#A7A7A7",
                        marginBlock: 2,
                      }}
                    />
                    Outras informações
                  </Subtitle>
                  {showItem[itemIndex] && (
                    <Box>
                      <Grid container spacing={2}>
                        <Grid item xs={4} lg={3}>
                          <Box>
                            <Input
                              title="Valor da ret. principal não efetuada"
                              type="amount"
                              value={data.principalRententionAmount}
                              onChange={(e) => {
                                onChangeINSSitem(
                                  {
                                    ...data,
                                    principalRententionAmount: handleMoney(e.target.value),
                                  },
                                  index,
                                  itemIndex
                                );
                              }}
                              disabled={disabled}
                            />
                          </Box>
                          <Box>
                            <Input
                              title="Valor da Retenção de Subcontratação"
                              type="amount"
                              value={data.subcontractorAmount}
                              onChange={(e) => {
                                onChangeINSSitem(
                                  {
                                    ...data,
                                    subcontractorAmount: handleMoney(e.target.value),
                                  },
                                  index,
                                  itemIndex
                                );
                              }}
                              disabled={disabled}
                            />
                          </Box>
                        </Grid>
                        <Grid item xs={8} lg={9}>
                          <Input
                            title="Anotações"
                            type="textarea"
                            styles={{ height: 42 }}
                            minRows={3}
                            maxlength="500"
                            value={data.annotation}
                            onChange={(e) => {
                              onChangeINSSitem(
                                {
                                  ...data,
                                  annotation: e.target.value,
                                },
                                index,
                                itemIndex
                              );
                            }}
                            disabled={disabled}
                          />
                        </Grid>
                      </Grid>

                      <Subtitle
                        style={{ margin: "20px 0px 10px 0px", cursor: "auto", userSelect: "none" }}
                      >
                        Inserir valor da retenção adicional (2%, 3% ou 4%)
                      </Subtitle>
                      <Grid container spacing={2}>
                        <Grid item xs={4} lg={3}>
                          <Input
                            title="Valor de 15 anos de contribuição"
                            type="amount"
                            value={data.serviceAmount15}
                            onChange={(e) => {
                              onChangeINSSitem(
                                {
                                  ...data,
                                  serviceAmount15: handleMoney(e.target.value),
                                },
                                index,
                                itemIndex
                              );
                            }}
                            disabled={disabled}
                          />
                        </Grid>
                        <Grid item xs={4} lg={3}>
                          <Input
                            title="Valor de 20 anos de contribuição"
                            type="amount"
                            value={data.serviceAmount20}
                            onChange={(e) => {
                              onChangeINSSitem(
                                {
                                  ...data,
                                  serviceAmount20: handleMoney(e.target.value),
                                },
                                index,
                                itemIndex
                              );
                            }}
                            disabled={disabled}
                          />
                        </Grid>
                        <Grid item xs={4} lg={3}>
                          <Input
                            title="Valor de 25 anos de contribuição"
                            type="amount"
                            value={data.serviceAmount25}
                            onChange={(e) => {
                              onChangeINSSitem(
                                {
                                  ...data,
                                  serviceAmount25: handleMoney(e.target.value),
                                },
                                index,
                                itemIndex
                              );
                            }}
                            disabled={disabled}
                          />
                        </Grid>
                        <Grid item xs={4} lg={3}>
                          <Input
                            title="Valor da Retenção Adicional"
                            type="amount"
                            value={handleMoney(
                              data.serviceAmount15 * 0.04 +
                                data.serviceAmount20 * 0.03 +
                                data.serviceAmount25 * 0.02
                            )}
                            disabled
                          />
                        </Grid>
                        <Grid item xs={4} lg={3}>
                          <Input
                            title="Valor da Ret. Adicional não efetuada"
                            type="amount"
                            value={data.additionalNotRetentionAmount}
                            onChange={(e) => {
                              onChangeINSSitem(
                                {
                                  ...data,
                                  additionalNotRetentionAmount: handleMoney(e.target.value),
                                },
                                index,
                                itemIndex
                              );
                            }}
                            disabled={disabled}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  )}
                  {!disabled && (
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        marginLeft: "auto",
                        marginTop: 10,
                        gap: 10,
                      }}
                    >
                      <RButton
                        variant="outlined"
                        sx={{ width: "120px", borderRadius: "8px", boxShadow: 0 }}
                        onClick={() => {
                          handleDelete(data, index, itemIndex);
                        }}
                      >
                        REMOVER
                      </RButton>
                      <RButton
                        sx={{ width: "120px", borderRadius: "8px", boxShadow: 0 }}
                        onClick={() => {
                          addMore();
                        }}
                      >
                        ADICIONAR
                      </RButton>
                    </Box>
                  )}
                </Box>
              </Box>
            );
          })
        ) : (
          <Skeleton
            sx={{
              marginBlock: 2,
              padding: 2,
              background: "#C9C9C9",
              borderRadius: 4,
            }}
            variant="rounded"
            width="100%"
            height={100}
          />
        )}
      </Box>
    </Box>
  );
}

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    marginBlock: 1,
  },
  geralValues: {
    container: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      gap: 2,
      marginBottom: 3,
      marginTop: 1,
    },
    box: {
      display: "flex",
      alignItems: "center",
      userSelect: "none",
      cursor: "auto",
      width: "100%",
      height: "100%",
      gap: 1,
    },
    text: {
      fontWeight: 700,
      fontSize: 14,
    },
    value: {
      paddingBlock: 2,
      paddingInline: 6,
      width: "50%",
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      border: "none",
      borderRadius: 5,
      backgroundColor: "#C1C1C1",
      overflow: "hidden",
    },
  },
};
