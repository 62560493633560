import React, { useState, useEffect, useContext, useRef } from "react";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
// components
import Table from "~/presentation/components/Table";
import TableActions from "~/presentation/components/TableActions";
import PageListHeader from "../components/PageListHeader";
import CustomDialogConfirmPassword from "~/presentation/views/Admin/User/components/FormConfirmPassword";
// functions
import triggerInputChange from "~/presentation/hooks/triggerInputChange";
import { DialogContext } from "~/presentation/providers/DialogProvider";
import { Container } from "@mui/material";
import RegisterEdit from "./RegisterEdit/RegisterEdit";

const columns = [
  { name: "name", label: "Nome" },
  { name: "email", label: "Email" },
  { name: "role", label: "Cargo" },
  { name: "actions", label: "Ações", options: { sortable: "false" } },
];

const User = ({ userUseCases, companyUseCases }) => {
  const { openBackdrop } = useContext(BackdropContext);
  const { setFeedbackSnackbar } = useContext(SnackbarContext);
  const { openDialogBox } = useContext(DialogContext);

  //Data
  const [users, setUsers] = useState([]);

  //Select
  const [companies, setCompanies] = useState([]);
  const [userGroups, setUserGroups] = useState([]);

  //Search
  const hiddenInputRef = useRef();
  const searchFieldRef = useRef();

  //Abrir forms criar / editar
  const [openForm, setOpenForm] = useState(false);
  const [formProps, setFormProps] = useState(false);

  const handleOpenForm = (data = null) => {
    setOpenForm(true);
    setFormProps(data);
  };

  const handleCloseForm = () => {
    setOpenForm(false);
    setFormProps(null);
  };

  const handleActions = (user) => {
    /** Checks if the logged user can edit/delete the user */
    const loggedUserRole = JSON.parse(localStorage.getItem("GT_user")).role;
    if (loggedUserRole === "superAdmin") {
      return (
        <TableActions
          user={user}
          handleDelete={() => deleteDialog(user.id)}
          handleEdit={() => handleOpenForm(user)}
          handlePromoteOrDemote={() => promoteOrDemoteDialog(user)}
        />
      );
    }
    if (loggedUserRole === "admin" && ["user", "manager"].includes(user.role)) {
      return <TableActions user={user} handleEdit={() => handleOpenForm(user)} />;
    }
    return false;
  };

  const fetchData = async () => {
    openBackdrop(true, "Carregando usuários");
    try {
      const apiUsers = await userUseCases.getUsers();
      apiUsers.forEach((user) => (user.actions = handleActions(user)));
      setUsers(apiUsers);
    } catch (error) {
      setFeedbackSnackbar({
        isOpen: true,
        message: "Erro ao carregar usuários " + error?.response?.data,
        type: "error",
      });
    } finally {
      openBackdrop(false);
    }
  };

  const cleanSearch = () => {
    triggerInputChange(hiddenInputRef.current);
    triggerInputChange(searchFieldRef.current);
  };

  const renderCustomDialogConfirmPassword = (user) => {
    return (
      <CustomDialogConfirmPassword
        handleConfirm={(password) => handlePromoteOrDemote(user, password)}
        handleClose={() => openDialogBox({ open: false })}
      />
    );
  };

  const promoteOrDemoteDialog = (user) => {
    openDialogBox({
      customComponent: () => renderCustomDialogConfirmPassword(user),
    });
  };

  const handlePromoteOrDemote = async (user, password) => {
    const action = user.role === "user" ? "promote" : "demote";
    openBackdrop(
      true,
      action === "promote"
        ? `Promovendo usuário a Admin.`
        : `Removendo privilégios de Admin do usuário.`
    );
    try {
      await userUseCases.promoteOrDemoteUser({
        id: user.id,
        action,
        password,
      });
      setFeedbackSnackbar({
        isOpen: true,
        message: "Usuário atualizado!",
        type: "success",
      });
      cleanSearch();
      await fetchData();
    } catch (error) {
      console.log(error.response);
      setFeedbackSnackbar({
        isOpen: true,
        message: "Erro ao atualizar usuário.",
        type: "error",
      });
    }

    openBackdrop(false);
  };

  const deleteDialog = (id) => {
    openDialogBox({
      message: `Deseja mesmo excluir este usuário?`,
      callback: () => handleDelete(id),
    });
  };

  const handleDelete = async (id) => {
    openBackdrop(true, "Excluindo usuário");

    try {
      await userUseCases.deleteUser(id);
      setFeedbackSnackbar({
        isOpen: true,
        message: "Usuário excluído!",
        type: "success",
      });
      setUsers((prev) => prev.filter((item) => item.id !== id));
      cleanSearch();
    } catch (error) {
      console.log(error.response);
      setFeedbackSnackbar({
        isOpen: true,
        message: "Erro ao excluir usuário.",
        type: "error",
      });
    }

    openBackdrop(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSearch = (value) => triggerInputChange(hiddenInputRef.current, value);

  const getUserGroups = async () => {
    setUserGroups(await userUseCases.getUserGroups());
  };

  const fetchCompanys = async () => {
    openBackdrop(true, "Carregando dados do formulário");
    try {
      const apiCompanies = await companyUseCases.getCompanies();
      apiCompanies.forEach((company) => {
        company.value = company.id;
        company.label = company.name;
      });
      setCompanies(apiCompanies);
      await getUserGroups();
    } catch (error) {
      setCompanies([]);
    }
    openBackdrop(false);
  };

  return (
    <Container maxWidth="100%">
      {openForm ? (
        <RegisterEdit
          data={formProps}
          onCloseForm={handleCloseForm}
          refreshData={fetchData}
          userUseCases={userUseCases}
          companies={companies}
          userGroups={userGroups}
          fetchCompanys={fetchCompanys}
        />
      ) : (
        <>
          <PageListHeader
            title="Usuário"
            titleAccess="Novo usuário"
            searchFieldRef={searchFieldRef}
            handleAdd={() => handleOpenForm()}
            handleSearch={handleSearch}
          />
          <Table searchRef={hiddenInputRef} columns={columns} data={users} title="Empresas" />
        </>
      )}
    </Container>
  );
};

export default User;
