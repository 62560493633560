import React, { useContext, useEffect } from "react";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import ContentForm from "./Form";
import HeaderForm from "../../components/HeaderForm/HeaderForm";
import { getRealTimeZoneDate } from "~/presentation/utils/getTimeZoneData";

function CreateEdit({
  data,
  onCloseForm,
  refreshData,
  categories,
  types,
  getSelectButtonsData,
  contentUseCases,
}) {
  const { openBackdrop } = useContext(BackdropContext);
  const { setFeedbackSnackbar } = useContext(SnackbarContext);
  const isCreate = !data;

  const getInitialValues = () => {
    if (data) {
      return { ...data, publishDate: getRealTimeZoneDate(data?.publishDate) };
    }
    return {
      id: 0,
      title: "",
      type: {},
      category: {},
      publishDate: new Date(),
      image: "",
      source: "",
      videoUrl: "",
      text: "",
      summary: "",
      audioUrl: null,
      isDraft: false,
      qsoTable: {
        inss1: [],
        inss2: [],
        inss3: [],
        irrf1: [],
        irrf2: [],
        csllPisCofins: [],
        iss1: [],
        iss2: [],
      },
    };
  };

  useEffect(() => {
    if (categories.length === 0 || types.length === 0) {
      getSelectButtonsData();
    }
  }, []);

  const handleSubmit = async (values) => {
    openBackdrop(true, `${isCreate ? "Criando" : "Editando"} conteúdo`);
    try {
      if (isCreate) {
        await contentUseCases.registerContent(values);
      } else {
        await contentUseCases.updateContent(values);
      }
      setFeedbackSnackbar({
        isOpen: true,
        message: `Conteúdo ${isCreate ? "criado" : "editado"} com sucesso!`,
        type: "success",
      });
      onCloseForm();
      await refreshData();
    } catch (err) {
      setFeedbackSnackbar({
        isOpen: true,
        message: `Erro: ${err?.response?.data}`,
        type: "error",
      });
    }
    openBackdrop(false);
  };

  return (
    <>
      <HeaderForm title={`${isCreate ? "Criar" : "Editar"} Conteúdo`} backFnc={onCloseForm} />
      <ContentForm
        initialValues={getInitialValues()}
        handleSubmit={handleSubmit}
        categories={categories}
        types={types}
      />
    </>
  );
}

export default CreateEdit;
