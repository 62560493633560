import React, { useState, useEffect, useContext, useRef } from "react";
import AppContext from "~/presentation/AppContext";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
// components
import Table from "~/presentation/components/Table";
import TableActions from "~/presentation/components/TableActions";
import PageListHeaderWithFilter from "~/presentation/views/Admin/components/PageListHeaderWithFilter";
//functions
import triggerInputChange from "~/presentation/hooks/triggerInputChange";
//utils
import typeTax from "~/presentation/utils/typeTax";
import { Filters } from "~/presentation/views/Admin/Answer/Filters";
import { DialogContext } from "~/presentation/providers/DialogProvider";
import { Container } from "@mui/material";
import CreateEdit from "./CreateEdit/CreateEdit";

const Answer = ({
  answerUseCases,
  itemAnswerUseCases,
  companyUseCases,
  cnaesUseCases,
  termUseCases,
}) => {
  const context = useContext(AppContext);
  const { openBackdrop } = useContext(BackdropContext);
  const { setFeedbackSnackbar } = useContext(SnackbarContext);
  const { openDialogBox } = useContext(DialogContext);

  //Data
  const [answers, setAnswers] = useState([]);

  //Data Select
  const [cnaes, setCnaes] = useState([]);
  const [itemsAnswer, setItemsAnswer] = useState([]);
  const [categories, setCategories] = useState([]);
  const [terms, setTerms] = useState([]);

  //Abrir forms criar / editar
  const [openForm, setOpenForm] = useState(false);
  const [formProps, setFormProps] = useState(false);

  const hiddenInputRef = useRef();
  const searchFieldRef = useRef();

  const handleOpenForm = (data = null, isCopy = false) => {
    setOpenForm(true);
    if (isCopy) {
      setFormProps({ ...data, isCopy: true });
    } else {
      setFormProps(data);
    }
  };

  const handleCloseForm = () => {
    setOpenForm(false);
    setFormProps(null);
  };

  const fetchData = async (filters) => {
    openBackdrop(true, "Carregando dados da tabela");
    const { data = [] } = await answerUseCases.listAnswersSummary(filters);
    const userIsSuperAdmin = context.haveSuperAdminAccess();

    data.forEach((answer) => {
      if (userIsSuperAdmin) {
        answer.actions = (
          <TableActions
            handleDelete={() => deleteDialog(answer.id)}
            handleEdit={() => handleOpenForm(answer)}
            handleCopy={() => handleOpenForm(answer, true)}
          />
        );
      } else {
        answer.actions = (
          <TableActions
            handleEdit={() => handleOpenForm(answer)}
            handleCopy={() => handleOpenForm(answer, true)}
          />
        );
      }
    });
    setAnswers(data);
    openBackdrop(false);
  };

  const columns = [
    {
      name: "gtAnswerName",
      label: "Nome",
    },
    {
      name: "description",
      label: "Descrição",
      sortOrder: {
        direction: "asc",
        compare: (a, b) => parseFloat(a) - parseFloat(b),
      },
    },
    {
      name: "typeTax",
      label: "Taxa",
      options: {
        filter: false,
        customBodyRender: (value) => {
          let type = typeTax.filter((item) => item.value == value)[0];
          return type.label;
        },
      },
    },
    {
      name: "isSimple",
      label: "Simples Nacional",
      options: {
        filter: false,
        customBodyRender: (value) => (value === true ? "Sim" : "Não"),
      },
    },
    { name: "actions", label: "Ações", options: { sortable: "false" } },
  ];

  const handleDelete = async (id) => {
    openBackdrop(true, "Excluindo resposta");
    try {
      await answerUseCases.deleteAnswer(id);
      setAnswers((prev) => prev.filter((item) => item.id !== id));
      cleanSearch();
    } catch (error) {
      setFeedbackSnackbar({
        isOpen: true,
        message: "Erro ao excluir resposta: Missing endpoint.",
        type: "error",
      });
    }
    openBackdrop(false);
  };

  const deleteDialog = (id) => {
    openDialogBox({
      message: `Deseja mesmo excluir esta resposta?`,
      callback: () => handleDelete(id),
    });
  };

  useEffect(() => {
    fetchData({ itemContent: "" });
  }, []);

  const handleSearch = (value) => triggerInputChange(hiddenInputRef.current, value);

  const cleanSearch = () => {
    triggerInputChange(hiddenInputRef.current);
    triggerInputChange(searchFieldRef.current);
  };

  const fetchSelectData = async () => {
    openBackdrop(true, "Carregando dados do formulário");
    if (cnaes.length === 0) {
      const { data } = await cnaesUseCases.listCnaes();
      setCnaes(data);
    }
    if (itemsAnswer.length === 0) {
      const apiTypeAnswers = await itemAnswerUseCases.getAll();
      setItemsAnswer(apiTypeAnswers);
    }
    if (categories.length === 0) {
      const categories = await companyUseCases.getCompanyCategories();
      setCategories(categories);
    }
    if (terms.length === 0) {
      const terms = await termUseCases.getAll();
      setTerms(terms);
    }
    openBackdrop(false);
  };

  return (
    <Container maxWidth="100%">
      {openForm ? (
        <CreateEdit
          data={formProps}
          onCloseForm={handleCloseForm}
          refreshData={fetchData}
          answerUseCases={answerUseCases}
          fetchSelectData={fetchSelectData}
          cnaes={cnaes}
          itemsAnswer={itemsAnswer}
          categories={categories}
          terms={terms}
        />
      ) : (
        <>
          <PageListHeaderWithFilter
            title="Resposta"
            titleAccess="Nova resposta"
            searchFieldRef={searchFieldRef}
            handleAdd={() => handleOpenForm()}
            handleSearch={handleSearch}
            filterComp={<Filters onFilter={fetchData} />}
          />
          <Table searchRef={hiddenInputRef} columns={columns} data={answers} title="Respostas" />
        </>
      )}
    </Container>
  );
};

export default Answer;
