import React, { useContext, useEffect, useState } from "react";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import HeaderForm from "../../components/HeaderForm/HeaderForm";
import CnaeForm from "./Form";

const newObj = {
  id: 0,
  code: "",
  description: "",
  detailCode: "",
  synonym: "",
  comment: "",
  cnaeAnswersLinked: null,
  serviceCodes: null,
};

function CreateEdit({
  data,
  onCloseForm,
  refreshData,
  cnaesUseCases,
  fetchSelectData,
  services,
  cnaesList,
}) {
  const { openBackdrop } = useContext(BackdropContext);
  const { setFeedbackSnackbar } = useContext(SnackbarContext);
  const isCreate = !data;

  const [initialValues, setInitialValues] = useState();

  useEffect(() => {
    if (services.length === 0 || cnaesList.length === 0) {
      fetchSelectData();
    }
  }, []);

  useEffect(() => {
    const fetch = async () => {
      openBackdrop(true, "Carregando informação");
      const result = await cnaesUseCases.getCnaeById(data.id);
      setInitialValues(result.data);
      openBackdrop(false);
    };
    if (data?.id) {
      fetch();
    } else {
      setInitialValues(newObj);
    }
  }, [data]);

  const handleSubmit = async (values) => {
    openBackdrop(true, `${isCreate ? "Cadastrando" : "Editando"} cnae`);
    try {
      if (isCreate) {
        await cnaesUseCases.createCnae(values);
      } else {
        await cnaesUseCases.updateCnae(values);
      }
      setFeedbackSnackbar({
        isOpen: true,
        message: `Cnae ${isCreate ? "cadastrado" : "editado"} com sucesso!`,
        type: "success",
      });
      onCloseForm();
      await refreshData();
    } catch (error) {
      setFeedbackSnackbar({
        isOpen: true,
        message: "Erro. " + error?.response?.data,
        type: "error",
      });
    } finally {
      openBackdrop(false);
    }
  };

  return (
    <>
      <HeaderForm title={`${isCreate ? "Criar" : "Editar"} CNAE`} backFnc={onCloseForm} />
      {initialValues && (
        <CnaeForm
          initialValues={initialValues}
          handleSubmit={handleSubmit}
          services={services}
          cnaes={cnaesList}
        />
      )}
    </>
  );
}

export default CreateEdit;
