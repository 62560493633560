import React, {useContext, useEffect, useRef, useState} from "react";
import { Formik, Form } from "formik";

import {
  Button,
  FormWrapper,
} from "~/presentation/views/GTFacil/Simulator/components";
import { SimulatorContext } from "~/presentation/views/GTFacil/Simulator/context/simulator-context";
import { floatToReais } from "~/presentation/utils/reais-parser";
import { ISSCalculator } from "~/presentation/views/GTFacil/Simulator/entities/iss-calculator";
import { validationSchema } from "./validationSchema.js";
import {
  BaseRetentionCalcValue,
  HasMaterialExclusion,
  ISSResultCard,
  MaterialExclusionValue,
  RetentionAliquotPercentage,
} from "~/presentation/views/GTFacil/Simulator/forms/common/iss/questions";
import { reportMapper } from "./reportMapper.js";

const initialValues = {
  hasExclusions: false,
  exclusionValue: 0,
  retentionAliquotPercentage: 5,
};

export const IssPJNotSimples = () => {
  const resultCardRef = useRef();
  const { simulatorValue, setInvalidSimulatorValue, handleCacheReport, cacheReportValues} = useContext(SimulatorContext);
  const [lastSubmitValues, setLastSubmitValues] = useState("");

  const issCalculatorFactory = (values) =>
    new ISSCalculator({
      simulatorValue,
      exclusionValue: values.exclusionValue,
      retentionAliquotPercentage: values.retentionAliquotPercentage,
    });

  const valuesNotChangedSinceLastSubmit = (values) => {
    return JSON.stringify(lastSubmitValues) === JSON.stringify(values);
  };

  const result = cacheReportValues.length > 0 ? cacheReportValues?.filter(report => report.tax === 3)[0]?.retentionValue : false;

  useEffect(() => {
    if(result) {
      resultCardRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [result]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={async (values, action) => {
        if (!simulatorValue) {
          setInvalidSimulatorValue(true);
          return;
        }
        action.setSubmitting(true);
        setLastSubmitValues(values);

        const issCalculator = issCalculatorFactory(values);

        action.setSubmitting(false);

        const response = reportMapper(issCalculator, issCalculator.issRetentionValue);

        handleCacheReport(response);
      }}
    >
      {({ setFieldValue, values }) => {
        const issCalculator = issCalculatorFactory(values);

        return (
          <Form>
            <FormWrapper>
              <HasMaterialExclusion
                onChange={() => {
                  setFieldValue("exclusionValue", initialValues.exclusionValue);
                }}
              />

              {values.hasExclusions === true ? (
                <MaterialExclusionValue />
              ) : null}
              <BaseRetentionCalcValue
                value={issCalculator.baseRetentionCalcValue}
              />
              <RetentionAliquotPercentage />
              <Button mt>Ver Resultado</Button>
              {result && valuesNotChangedSinceLastSubmit(values) ? (
                <ISSResultCard
                  value={floatToReais(result)}
                  componentRef={resultCardRef}
                />
              ) : null}
            </FormWrapper>
          </Form>
        );
      }}
    </Formik>
  );
};
