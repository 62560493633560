import React from "react";
import { ActionsWrapper } from "~/presentation/components/TableActions/styles";
// components
import { IconButton, Tooltip } from "@mui/material";
// icons
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import AddIcon from "@mui/icons-material/Add";

const TableActions = ({
  user,
  handleDelete,
  handleEdit,
  handlePromoteOrDemote,
  handleCopy,
  handleSubscribe,
}) => (
  <ActionsWrapper>
    {handleDelete && (
      <Tooltip title="Eliminar">
        <IconButton onClick={handleDelete}>
          <DeleteOutlineIcon />
        </IconButton>
      </Tooltip>
    )}
    {handleEdit && (
      <Tooltip title="Editar">
        <IconButton onClick={handleEdit}>
          <EditIcon />
        </IconButton>
      </Tooltip>
    )}
    {handlePromoteOrDemote && (
      <Tooltip title={user.role === "user" ? "Promover" : "Despromover"}>
        <IconButton onClick={handlePromoteOrDemote}>
          {user.role === "user" ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
        </IconButton>
      </Tooltip>
    )}
    {handleCopy && (
      <Tooltip title="Copiar dados">
        <IconButton onClick={handleCopy}>
          <FileCopyIcon />
        </IconButton>
      </Tooltip>
    )}
    {handleSubscribe && (
      <Tooltip title="Subscrever">
        <IconButton onClick={handleSubscribe}>
          <AddIcon />
        </IconButton>
      </Tooltip>
    )}
  </ActionsWrapper>
);

export default TableActions;
