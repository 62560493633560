import React, { useContext } from "react";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import HeaderForm from "../../components/HeaderForm/HeaderForm";
import TermForm from "./Form";

function CreateEdit({ data, onCloseForm, refreshData, termUseCases }) {
  const { openBackdrop } = useContext(BackdropContext);
  const { setFeedbackSnackbar } = useContext(SnackbarContext);
  const isCreate = !data;

  const getInitialValues = () => {
    if (data) {
      return data;
    }
    return { id: 0, term: "" };
  };

  const handleSubmit = async (values) => {
    try {
      openBackdrop(true, `${isCreate ? "Cadastrando" : "Editando"} termo`);
      if (isCreate) {
        await termUseCases.createTerm(values);
      } else {
        await termUseCases.updateTerm(values);
      }
      setFeedbackSnackbar({
        isOpen: true,
        message: `Termo ${isCreate ? "criado" : "editado"} com sucesso!`,
        type: "success",
      });
      onCloseForm();
      await refreshData();
    } catch (error) {
      setFeedbackSnackbar({
        isOpen: true,
        message: "Erro. " + error?.response?.data,
        type: "error",
      });
    } finally {
      openBackdrop(false);
    }
  };

  return (
    <>
      <HeaderForm title={`${isCreate ? "Cadastrar" : "Editar"} Termo`} backFnc={onCloseForm} />
      <TermForm initialValues={getInitialValues()} handleSubmit={handleSubmit} />
    </>
  );
}

export default CreateEdit;
