import React from "react";
// packages
import { Formik, Field } from "formik";
//components
import ErrorMessage from "~/presentation/components/ErrorMessage";
import PrimaryButton from "~/presentation/components/PrimaryButton";
import TextInput from "~/presentation/components/TextInput";

import { Container, Form } from "./styles";
import FormSubmitButtonsDock from "../../../components/FormSubmitButtonsDock";
import validationSchema from "./validationSchema";

const TermForm = ({ initialValues, handleSubmit }) => {
  return (
    <Container className="scrollbar-white">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, action) => {
          action.setSubmitting(true);
          await handleSubmit(values);
          action.setSubmitting(false);
        }}
      >
        {() => (
          <Form>
            <div className="form-grid">
              <div className="col-1_5">
                <Field
                  name="term"
                  type="text"
                  label="Termo de Pesquisa*"
                  multiline
                  as={TextInput}
                />
                <ErrorMessage name="term" />
              </div>
            </div>

            <FormSubmitButtonsDock>
              <PrimaryButton className="submit-btn" type="submit">
                Salvar
              </PrimaryButton>
            </FormSubmitButtonsDock>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default TermForm;
