import React, { useReducer, useState } from "react";
import { useEffect } from "react";
import Table from "~/presentation/components/Table";
import triggerInputChange from "~/presentation/hooks/triggerInputChange";
import { useRef } from "react";
import TableActions from "~/presentation/components/TableActions";
import { useContext } from "react";
import AppContext from "~/presentation/AppContext";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import { DialogContext } from "~/presentation/providers/DialogProvider";
import { Container } from "@mui/material";
import PageListHeader from "../components/PageListHeader";
import CreateEdit from "./CreateEdit/CreateEdit";

const columns = [
  { name: "term", label: "Termo de busca" },
  { name: "actions", label: "Ações", options: { sortable: "false" } },
];

const Terms = ({ termUseCases }) => {
  const context = useContext(AppContext);
  const { openBackdrop } = useContext(BackdropContext);
  const { setFeedbackSnackbar } = useContext(SnackbarContext);
  const { openDialogBox } = useContext(DialogContext);

  const [terms, setTerms] = useState([]);

  //Abrir forms criar / editar
  const [openForm, setOpenForm] = useState(false);
  const [formProps, setFormProps] = useState(false);

  const hiddenInputRef = useRef();
  const searchFieldRef = useRef();

  const handleOpenForm = (data = null) => {
    setOpenForm(true);
    setFormProps(data);
  };

  const handleCloseForm = () => {
    setOpenForm(false);
    setFormProps(null);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    openBackdrop(true, "Carregando os dados");
    const terms = await termUseCases.getAll();
    const userIsSuperAdmin = context.haveSuperAdminAccess();
    terms?.forEach((term) => {
      if (userIsSuperAdmin) {
        term.actions = (
          <TableActions
            handleDelete={() => deleteDialog(term.id)}
            handleEdit={() => handleOpenForm(term)}
          />
        );
      } else {
        term.actions = <TableActions handleEdit={() => handleOpenForm(term)} />;
      }
    });
    setTerms(terms);
    openBackdrop(false);
  };

  const deleteDialog = (id) => {
    openDialogBox({
      message: `Deseja mesmo excluir este termo?`,
      callback: () => handleDelete(id),
    });
  };

  const handleDelete = async (id) => {
    openBackdrop(true, "Excluindo termo");
    try {
      await termUseCases.deleteTerm(id);
      setTerms((prev) => prev.filter((item) => item.id !== id));
      cleanSearch();
    } catch (error) {
      setFeedbackSnackbar({
        isOpen: true,
        message: "Erro ao excluir o termo",
        type: "error",
      });
    }
    openBackdrop(false);
  };

  const handleSearch = (value) => triggerInputChange(hiddenInputRef.current, value);

  const cleanSearch = () => {
    triggerInputChange(hiddenInputRef.current);
    triggerInputChange(searchFieldRef.current);
  };

  return (
    <Container maxWidth="100%">
      {openForm ? (
        <CreateEdit
          data={formProps}
          onCloseForm={handleCloseForm}
          refreshData={fetchData}
          termUseCases={termUseCases}
        />
      ) : (
        <>
          <PageListHeader
            title="Termos de Pesquisa"
            titleAccess="Novo Termo"
            searchFieldRef={searchFieldRef}
            handleAdd={() => handleOpenForm()}
            handleSearch={handleSearch}
          />
          <Table searchRef={hiddenInputRef} columns={columns} data={terms} />
        </>
      )}
    </Container>
  );
};

export default Terms;
