import React, { useContext, useEffect, useState } from "react";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import HeaderForm from "../../components/HeaderForm/HeaderForm";
import AnnotationForm from "./Form";

function CreateEdit({
  data,
  onCloseForm,
  refreshData,
  annotationUseCases,
  fetchSelectOptions,
  cnaes,
  terms,
}) {
  const { openBackdrop } = useContext(BackdropContext);
  const { setFeedbackSnackbar } = useContext(SnackbarContext);
  const isCreate = !data;
  const [initialValues, setInitialValues] = useState();

  useEffect(() => {
    if (cnaes.length === 0 || terms.length === 0) {
      fetchSelectOptions();
    }
  }, []);

  useEffect(() => {
    const fetch = async () => {
      openBackdrop(true, "Carregando informação");
      const result = await annotationUseCases.getAnnotation(data.id);
      setInitialValues(result.data);
      openBackdrop(false);
    };
    if (data?.id) {
      fetch();
    } else {
      setInitialValues({
        id: 0,
        name: "",
        text: "",
        cnaes: [],
        terms: [],
      });
    }
  }, [data]);

  const handleSubmit = async (values) => {
    try {
      openBackdrop(true, `${isCreate ? "Criando" : "Editando"} anotação`);
      if (isCreate) {
        await annotationUseCases.createAnnotation(values);
      } else {
        await annotationUseCases.updateAnnotation(values);
      }
      setFeedbackSnackbar({
        isOpen: true,
        message: `Anotação ${isCreate ? "criada" : "editada"}.`,
        type: "success",
      });
      onCloseForm();
      await refreshData();
    } catch (error) {
      setFeedbackSnackbar({
        isOpen: true,
        message: "Erro. " + error?.response?.data,
        type: "error",
      });
    } finally {
      openBackdrop(false);
    }
  };

  return (
    <>
      <HeaderForm title={`${isCreate ? "Criar" : "Editar"} Anotação`} backFnc={onCloseForm} />
      {initialValues && (
        <AnnotationForm
          initialValues={initialValues}
          handleSubmit={handleSubmit}
          cnaes={cnaes}
          terms={terms}
        />
      )}
    </>
  );
}

export default CreateEdit;
