import React, { useContext } from "react";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import HeaderForm from "../../components/HeaderForm/HeaderForm";
import CategoryForm from "./Form";

function CreateEdit({ data, onCloseForm, refreshData, blogCategoryUseCases }) {
  const { openBackdrop } = useContext(BackdropContext);
  const { setFeedbackSnackbar } = useContext(SnackbarContext);
  const isCreate = !data;

  const getInitialValues = () => {
    if (data) {
      return data;
    }
    return {
      title: "",
      isSerie: false,
      description: "",
      imageUrl: "",
    };
  };

  const handleSubmit = async (values) => {
    openBackdrop(true, `${isCreate ? "Criando" : "Editando"} categoria`);
    try {
      if (isCreate) {
        await blogCategoryUseCases.createBlogCategory(values);
      } else {
        await blogCategoryUseCases.updateBlogCategory(values);
      }
      setFeedbackSnackbar({
        isOpen: true,
        message: `Categoria ${isCreate ? "criado" : "editada"} com sucesso!`,
        type: "success",
      });
      onCloseForm();
      await refreshData();
    } catch (error) {
      setFeedbackSnackbar({
        isOpen: true,
        message: "Erro ao cadastrar categoria: missing endpoint",
        type: "error",
      });
    }
    openBackdrop(false);
  };

  return (
    <>
      <HeaderForm title={`${isCreate ? "Criar" : "Editar"} Categoria`} backFnc={onCloseForm} />
      <CategoryForm initialValues={getInitialValues()} handleSubmit={handleSubmit} />
    </>
  );
}

export default CreateEdit;
