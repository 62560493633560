import React, { useState, useEffect, useContext, useRef } from "react";
import AppContext from "~/presentation/AppContext";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
// components
import Table from "~/presentation/components/Table";
import TableActions from "~/presentation/components/TableActions";
//functions
import triggerInputChange from "~/presentation/hooks/triggerInputChange";
import { DialogContext } from "~/presentation/providers/DialogProvider";
import { Container } from "@mui/material";
import PageListHeader from "../components/PageListHeader";
import CreateEdit from "./CreateEdit/CreateEdit";

const columns = [
  { name: "title", label: "Título" },
  { name: "order", label: "Ordem" },
  { name: "actions", label: "Ações", options: { sortable: "false" } },
];

const TypeAnswer = ({ typesAnswerUseCases }) => {
  const context = useContext(AppContext);
  const { openBackdrop } = useContext(BackdropContext);
  const { setFeedbackSnackbar } = useContext(SnackbarContext);
  const { openDialogBox } = useContext(DialogContext);

  const [typesAnswer, setTypesAnswer] = useState([]);

  //Abrir forms criar / editar
  const [openForm, setOpenForm] = useState(false);
  const [formProps, setFormProps] = useState(false);

  const hiddenInputRef = useRef();
  const searchFieldRef = useRef();

  const handleOpenForm = (data = null) => {
    setOpenForm(true);
    setFormProps(data);
  };

  const handleCloseForm = () => {
    setOpenForm(false);
    setFormProps(null);
  };

  const fetchData = async () => {
    openBackdrop(true, "Carregando dados da tabela");

    const apiTypesAnswer = await typesAnswerUseCases.getTypesAnswer();

    const userIsSuperAdmin = context.haveSuperAdminAccess();

    apiTypesAnswer?.forEach((typeAnswer) => {
      if (userIsSuperAdmin) {
        typeAnswer.actions = (
          <TableActions
            handleDelete={() => deleteDialog(typeAnswer.id)}
            handleEdit={() => handleOpenForm(typeAnswer)}
          />
        );
      } else {
        typeAnswer.actions = <TableActions handleEdit={() => handleOpenForm(typeAnswer)} />;
      }
    });
    setTypesAnswer(apiTypesAnswer);
    openBackdrop(false);
  };

  const handleDelete = async (id) => {
    openBackdrop(true, "Excluindo tipo de resposta");
    try {
      await typesAnswerUseCases.deleteTypeAnswer(id);
      setTypesAnswer((prev) => prev.filter((item) => item.id !== id));
      cleanSearch();
    } catch (error) {
      setFeedbackSnackbar({
        isOpen: true,
        message: "Erro. " + error?.response?.data,
        type: "error",
      });
    }
    openBackdrop(false);
  };

  const deleteDialog = (id) => {
    openDialogBox({
      message: `Deseja mesmo excluir este serviço?`,
      callback: () => handleDelete(id),
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSearch = (value) => triggerInputChange(hiddenInputRef.current, value);

  const cleanSearch = () => {
    triggerInputChange(hiddenInputRef.current);
    triggerInputChange(searchFieldRef.current);
  };

  return (
    <Container maxWidth="100%">
      {openForm ? (
        <CreateEdit
          data={formProps}
          onCloseForm={handleCloseForm}
          refreshData={fetchData}
          typesAnswerUseCases={typesAnswerUseCases}
        />
      ) : (
        <>
          <PageListHeader
            title="Tipo de resposta"
            titleAccess="Novo tipo"
            searchFieldRef={searchFieldRef}
            handleSearch={handleSearch}
          />
          <Table searchRef={hiddenInputRef} columns={columns} data={typesAnswer} />
        </>
      )}
    </Container>
  );
};

export default TypeAnswer;
