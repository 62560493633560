import React from "react";
// packages
import { Formik, Field } from "formik";
//components
import ErrorMessage from "~/presentation/components/ErrorMessage";
import PrimaryButton from "~/presentation/components/PrimaryButton";
import TextInput from "~/presentation/components/TextInput";
import Select from "~/presentation/components/Select";
import { Container, Form } from "~/presentation/views/Admin/Service/CreateEdit/Form/styles";
import FormSubmitButtonsDock from "~/presentation/views/Admin/components/FormSubmitButtonsDock";
// schema
import validationSchema from "./validationSchema";
import TextEditor from "~/presentation/components/TextEditor";
import { dataToSelectMapper } from "~/presentation/functions/dataToSelectMapper";

const ItemAnswerForm = ({ handleSubmit, initialValues, data = [] }) => {
  const mappedGtAnswerItems = data.length > 0 && dataToSelectMapper(data);

  return (
    <Container className="scrollbar-white">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, action) => {
          const submitValues = {
            id: values.id,
            title: values.title,
            text: values.text,
            gtAnswerItemType: {
              id: values.gtAnswerItemType.value,
              title: values.gtAnswerItemType.title,
            },
          };
          action.setSubmitting(true);
          await handleSubmit(submitValues);
          action.setSubmitting(false);
        }}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <h2>Item de Resposta</h2>
            <div className="form-grid">
              <div className="col-1_5">
                <Field name="title" type="text" label="Título*" as={TextInput} />
                <ErrorMessage name="title" />
              </div>
              <div className="col-1_5">
                <Field
                  name="gtAnswerItemType"
                  label="Tipo de Resposta"
                  component={Select}
                  value={values.gtAnswerItemType}
                  options={mappedGtAnswerItems}
                  getOptionLabel={(option) => option.title}
                  placeholder="Tipo de resposta *"
                  onChange={(option) => {
                    setFieldValue("gtAnswerItemType", option);
                  }}
                />
                <ErrorMessage name="gtAnswerItemType" />
              </div>
              <div className="col-1_5">
                <TextEditor
                  initialValue={values.text}
                  name="text"
                  height={400}
                  handleEditorChange={(content) => {
                    setFieldValue("text", content);
                  }}
                />
                <ErrorMessage name="text" />
              </div>
            </div>

            <FormSubmitButtonsDock>
              <PrimaryButton className="submit-btn" type="submit">
                Salvar
              </PrimaryButton>
            </FormSubmitButtonsDock>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default ItemAnswerForm;
