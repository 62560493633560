import React, { useContext } from "react";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import HeaderForm from "../../components/HeaderForm/HeaderForm";
import LegislationCategoryForm from "./Form";

function CreateEdit({ data, onCloseForm, refreshData, legislationCategoryUseCases }) {
  const { openBackdrop } = useContext(BackdropContext);
  const { setFeedbackSnackbar } = useContext(SnackbarContext);
  const isCreate = !data;

  const getInitialValues = () => {
    if (data) {
      return data;
    }
    return {
      name: "",
      letter: "",
    };
  };

  const handleSubmit = async (values) => {
    openBackdrop(true, `${isCreate ? "Criando" : "Editando"} categoria`);
    try {
      if (isCreate) {
        await legislationCategoryUseCases.createLegislationCategory(values);
      } else {
        await legislationCategoryUseCases.updateLegislationCategory(values);
      }
      setFeedbackSnackbar({
        isOpen: true,
        message: `Categoria ${isCreate ? "criada" : "editada"} com sucesso!`,
        type: "success",
      });
      onCloseForm();
      await refreshData();
    } catch (error) {
      setFeedbackSnackbar({
        isOpen: true,
        message: "Erro. " + error?.response?.data,
        type: "error",
      });
    }
    openBackdrop(false);
  };

  return (
    <>
      <HeaderForm title={`${isCreate ? "Criar" : "Editar"} Categoria`} backFnc={onCloseForm} />
      <LegislationCategoryForm initialValues={getInitialValues()} handleSubmit={handleSubmit} />
    </>
  );
}

export default CreateEdit;
