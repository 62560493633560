import React, { useEffect, useState, useContext } from "react";
import { Container, Grid, Box, Typography as Text } from "@mui/material";
import Input from "../../../../components/Common/Inputs";
import RButton from "../../../../components/Common/Buttons/RButton";
import validationSchema from "./validationSchema";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import { ApiCompanyUseCases } from "~/data/usecases/company/apiCompany";
import { makeHttpClient } from "~/main/factories/infra/http-client";
import removeMask from "~/presentation/functions/removeMask";

export default function MatrizCompany({ data }) {
  const { setFeedbackSnackbar } = useContext(SnackbarContext);
  const companyUseCases = new ApiCompanyUseCases(makeHttpClient());

  const [values, setValues] = useState({
    id: 0,
    cnpj: "",
    name: "",
    mobilePhone: "",
    zipCode: "",
    complement: "",
    contactName: "",
    contactCpf: "",
    email: "",
    address: "",
    homePhone: "",
    isECD: false,
    isExemptionFrom: false,
    isExemptionAgreement: false,
    isUnion: false,
    isEFR: false,
    cnpjEFR: "",
    taxClassificationId: null,
    codeUG: "",
  });
  const [errors, setErrors] = useState({});
  const [companyUser, setCompanyUser] = useState(data);

  useEffect(() => {
    if (data) {
      setValues({
        id: data.id,
        cnpj: data.cnpj,
        name: data.name,
        mobilePhone: data.mobilePhone,
        zipCode: data.zipCode,
        complement: data.complement,
        contactName: data.contactName,
        contactCpf: data.contactCpf,
        email: data.email,
        address: data.address,
        homePhone: data.homePhone,
        isECD: data.isECD,
        isExemptionFrom: data.isExemptionFrom,
        isExemptionAgreement: data.isExemptionAgreement,
        isUnion: data.isUnion,
        isEFR: values.isEFR,
        cnpjEFR: data.cnpjEFR,
        taxClassificationId: data.taxClassification?.id,
        legalNature: data.legalNature,
        codeUG: data.codeUG,
      });
      setCompanyUser(data);
    }
  }, [data]);

  const handleRemoveDots = (string, type) => {
    if (type === "cnpj") {
      return string.replace(/[./-]/g, "");
    }
    if (type === "cpf") {
      return string.replace(/[.-]/g, "");
    }
  };

  const handleSubmitCompany = async () => {
    const dataToSubmit = {
      id: values.id,
      cnpj: values.cnpj,
      name: values.name,
      active: companyUser.active,
      mobilePhone: values.mobilePhone,
      zipCode: removeMask(values.zipCode, "cep"),
      complement: values.complement,
      dateEnd: companyUser.endDate,
      dateStart: companyUser.startDate,
      contactName: values.contactName,
      contactCpf: values.contactCpf,
      email: values.email,
      address: values.address,
      homePhone: values.homePhone,
      dateCompanyRegistered: companyUser.dateCompanyRegistered,
      legalNatureId: companyUser.legalNature?.id,
      categoryId: companyUser.category?.id,
      taxClassificationId: null,
      cityId: companyUser.city?.id,
      planId: companyUser.plan?.id,
      isECD: values.isECD,
      isExemptionFrom: values.isExemptionFrom,
      isExemptionAgreement: values.isExemptionAgreement,
      isUnion: values.isUnion,
      isEFR: values.isEFR,
      cnpjEFR: values.cnpjEFR,
      codeUG: values.codeUG,
    };
    validationSchema
      .validate(dataToSubmit, { abortEarly: false })
      .then(async () => {
        setErrors({});
        try {
          await companyUseCases.update(dataToSubmit);
          setFeedbackSnackbar({
            isOpen: true,
            message: "Informações atualizadas com sucesso!",
            type: "success",
          });
        } catch (error) {
          setFeedbackSnackbar({
            isOpen: true,
            message: "Erro ao atualizar Informações",
            type: "error",
          });
        }
      })
      .catch((errors) => {
        setErrors(
          errors?.inner.reduce((acc, error) => {
            acc[error.path] = error.message;
            return acc;
          }, {})
        );
      });
  };

  return (
    <>
      <Container maxWidth="100%" sx={{ paddingBlock: 3 }}>
        <Box sx={{ backgroundColor: "#E6E6E6", borderRadius: 2 }}>
          <Grid container spacing={2} sx={{ paddingInline: 4, paddingBlock: 2 }}>
            <Grid item xs={4} sm={11}>
              <Text style={styles.Title}>Informações da Empresa</Text>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Input
                type="mask"
                mask={"99.999.999/9999-99"}
                title="CNPJ"
                value={values.cnpj}
                disabled
              />
            </Grid>
            <Grid item xs={12} sm={7}>
              <Input
                type="text"
                placeholder="Estabelecimento"
                title="Estabelecimento"
                value={values?.name || "carregando dados do estabelecimento"}
                disabled
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <Input
                type="text"
                placeholder="Cód. UG"
                title="Cód. Unidade Gestora"
                value={values?.codeUG}
                onChange={(e) => setValues({ ...values, codeUG: e.target.value })}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <Input
                type="text"
                placeholder="Natureza Jurídica"
                title="Natureza Jurídica"
                value={values?.legalNature?.description}
                disabled
              />
            </Grid>
            <Grid item xs={12} sm={5}>
              <Input
                type="text"
                placeholder="Endereço"
                title="Endereço"
                value={values.address}
                onChange={(e) => setValues({ ...values, address: e.target.value })}
                error={!!errors.address}
                errorText={errors.address}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <Input
                type="text"
                placeholder="Complemento"
                title="Complemento"
                value={values.complement}
                onChange={(e) => setValues({ ...values, complement: e.target.value })}
                error={!!errors.complement}
                errorText={errors.complement}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <Input
                type="mask"
                mask="99999-999"
                placeholder="99999-999"
                title="CEP"
                value={values.zipCode}
                onChange={(e) => setValues({ ...values, zipCode: e.target.value })}
                error={!!errors.zipCode}
                errorText={errors.zipCode}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <Input
                type="text"
                title="Responsável pelas Informações"
                placeholder="Insira nome"
                value={values.contactName}
                onChange={(e) => setValues({ ...values, contactName: e.target.value })}
                error={!!errors.contactName}
                errorText={errors.contactName}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <Input
                type="mask"
                mask={"999.999.999-99"}
                placeholder="000.000.000-00"
                title="CPF do Responsável"
                value={values.contactCpf}
                onChange={(e) =>
                  setValues({ ...values, contactCpf: handleRemoveDots(e.target.value, "cpf") })
                }
                error={!!errors.contactCpf}
                errorText={errors.contactCpf}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Input
                type="email"
                placeholder="E-mail"
                title="E-mail"
                value={values.email}
                onChange={(e) => setValues({ ...values, email: e.target.value })}
                error={!!errors.email}
                errorText={errors.email}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <Input
                type="mask"
                mask="(99) 9999-9999"
                placeholder="(99) 9999-9999"
                title="Telefone"
                value={values.homePhone}
                onChange={(e) => setValues({ ...values, homePhone: e.target.value })}
                error={!!errors.homePhone}
                errorText={errors.homePhone}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <Input
                type="mask"
                mask="(99) 99999-9999"
                placeholder="(99) 99999-9999"
                title="Celular"
                value={values.mobilePhone}
                onChange={(e) => setValues({ ...values, mobilePhone: e.target.value })}
                error={!!errors.mobilePhone}
                errorText={errors.mobilePhone}
              />
            </Grid>
          </Grid>
          <Box sx={{ display: "flex", justifyContent: "end", gap: 1, p: 4 }}>
            {/* <RButton sx={{ width: "150px" }} variant="outlined" onClick={() => setValues(data)}>
          Cancelar
        </RButton> */}
            <RButton sx={{ width: "150px" }} onClick={handleSubmitCompany}>
              Salvar
            </RButton>
          </Box>
        </Box>
      </Container>
    </>
  );
}

const styles = {
  Title: {
    fontFamily: "Montserrat",
    fontSize: "24px",
    fontWeight: 700,
    color: "#404040",
    textAlign: "left",
    display: "flex",
    alignItems: "center",
  },
};
