import React, { useState, useEffect, useContext, useRef } from "react";
import AppContext from "~/presentation/AppContext";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
// components
import Table from "~/presentation/components/Table";
import TableActions from "~/presentation/components/TableActions";
import PageListHeader from "../components/PageListHeader";
//functions
import triggerInputChange from "~/presentation/hooks/triggerInputChange";
import { DialogContext } from "~/presentation/providers/DialogProvider";
import { Container } from "@mui/material";
import { formatDateToTable } from "~/presentation/functions/date";
import CreateEdit from "./CreateEdit/CreateEdit";

const columns = [
  {
    name: "type",
    label: "Tipo",
    options: {
      filter: false,
      customBodyRender: (value) => value.name,
    },
  },
  {
    name: "title",
    label: "Título",
  },
  {
    name: "publishDate",
    label: "Data",
    options: {
      filter: false,
      customBodyRender: (value) => formatDateToTable(value),
    },
  },
  {
    name: "isDraft",
    label: "Rascunho",
    options: {
      customBodyRender: (value) => (value ? "Sim" : "Não"),
    },
  },
  { name: "actions", label: "Ações", options: { sortable: "false" } },
];

const Content = ({ contentUseCases }) => {
  const context = useContext(AppContext);
  const { openBackdrop } = useContext(BackdropContext);
  const { setFeedbackSnackbar } = useContext(SnackbarContext);
  const { openDialogBox } = useContext(DialogContext);

  //Data
  const [contents, setContents] = useState([]);

  //Select
  const [categories, setCategories] = useState([]);
  const [types, setTypes] = useState([]);

  //Abrir forms criar / editar
  const [openForm, setOpenForm] = useState(false);
  const [formProps, setFormProps] = useState(false);

  const hiddenInputRef = useRef();
  const searchFieldRef = useRef();

  const handleOpenForm = (data = null) => {
    setOpenForm(true);
    setFormProps(data);
  };

  const handleCloseForm = () => {
    setOpenForm(false);
    setFormProps(null);
  };

  const fetchData = async () => {
    openBackdrop(true, "Carregando dados da tabela");
    const fetchedContents = await contentUseCases.getAllContents();
    parseContentsData(fetchedContents);
    setContents(fetchedContents);
    openBackdrop(false);
  };

  const parseContentsData = (contents) => {
    const userIsSuperAdmin = context.haveSuperAdminAccess();

    contents.forEach((content) => {
      if (userIsSuperAdmin) {
        content.actions = (
          <TableActions
            handleDelete={() => deleteDialog(content.id)}
            handleEdit={() => handleOpenForm(content)}
          />
        );
      } else {
        content.actions = <TableActions handleEdit={() => handleOpenForm(content)} />;
      }
    });
  };

  const handleDelete = async (id) => {
    openBackdrop(true, "Excluindo conteúdo");

    try {
      await contentUseCases.deleteContent(id);
      setFeedbackSnackbar({
        isOpen: true,
        message: "Conteúdo excluído!",
        type: "success",
      });
      setContents((prev) => prev.filter((item) => item.id !== id)); //eliminar conteúdo da tabela
      cleanSearch();
    } catch (error) {
      setFeedbackSnackbar({
        isOpen: true,
        message: "Erro ao excluir o conteúdo. " + error?.response?.data,
        type: "error",
      });
    }

    openBackdrop(false);
  };

  const deleteDialog = (id) => {
    openDialogBox({
      message: `Deseja mesmo excluir este conteúdo?`,
      callback: () => handleDelete(id),
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSearch = (value) => triggerInputChange(hiddenInputRef.current, value);

  const cleanSearch = () => {
    triggerInputChange(hiddenInputRef.current);
    triggerInputChange(searchFieldRef.current);
  };

  const getSelectButtonsData = async () => {
    if (categories.length === 0) {
      const cat = await contentUseCases.getLegislationCategories();
      const categories = cat.map((category) => ({
        value: category.id,
        label: category.name,
      }));
      setCategories(categories);
    }
    if (types.length === 0) {
      const typ = await contentUseCases.getContentTypes();
      const types = typ.map((type) => ({
        value: type,
        label: type.name,
      }));
      setTypes(types);
    }
  };

  return (
    <Container maxWidth="100%">
      {openForm ? (
        <CreateEdit
          data={formProps}
          onCloseForm={handleCloseForm}
          refreshData={fetchData}
          contentUseCases={contentUseCases}
          categories={categories}
          types={types}
          getSelectButtonsData={getSelectButtonsData}
        />
      ) : (
        <>
          <PageListHeader
            title="Conteúdo"
            titleAccess="Novo conteúdo"
            searchFieldRef={searchFieldRef}
            handleAdd={() => handleOpenForm()}
            handleSearch={handleSearch}
          />
          <Table searchRef={hiddenInputRef} columns={columns} data={contents} />
        </>
      )}
    </Container>
  );
};

export default Content;
