import React, { useContext, useEffect, useState } from "react";

import {
  Box,
  FormControl,
  FormControlLabel,
  Typography as Text,
  InputLabel,
  MenuItem,
  Select,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  TextField,
  TablePagination,
  Stack,
  Modal,
  Grid,
  Switch,
  FormHelperText,
  CircularProgress,
} from "@mui/material";

import AppContext from "~/presentation/AppContext";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";

import { Container } from "./styles";
import validationSchema from "./validationSchema";
import AddIcon from "@mui/icons-material/Add";
import InputMask from "react-input-mask";
import { DialogContext } from "~/presentation/providers/DialogProvider";
import { useTableFilters } from "~/presentation/hooks/useTableFilters";

export const NewUserForm = ({ userUseCases, companyId }) => {
  const [users, setUsers] = useState();
  const [filteredUsers, setFilteredUsers] = useState();
  const [user, setUser] = useState({
    // Default initial state
    userId: "",
    name: "",
    email: "",
    phone: "",
    mobilePhone: "",
    password: "",
    confirmPassword: "",
    role: "",
    active: true,
  });
  const [modalTitle, setModalTitle] = useState("Novo Usuário");
  const [userGroups, setUserGroups] = useState([]);
  const { openBackdrop } = useContext(BackdropContext);
  const { setFeedbackSnackbar } = useContext(SnackbarContext);
  const { openDialogBox } = useContext(DialogContext);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [open, setOpen] = useState(false);
  const [isNewUser, setIsNewUser] = useState("");
  const [errors, setErrors] = useState({});
  const { handleSearchTable } = useTableFilters();

  useEffect(() => {
    getUserGroups();
  }, []);

  const getUserGroups = () => {
    setUserGroups(userUseCases.getUserGroups());
  };

  const getUsers = async () => {
    const result = await userUseCases.getUsers();
    result.forEach((u) => {
      u.value = u?.userId;
      u.label = u?.name;
    });
    setUsers(result);
    setFilteredUsers(result);
  };

  const getUserById = async (userId) => {
    const userData = await userUseCases.getUserById(userId);
    setUser(userData);
  };

  useEffect(() => {
    getUsers();
  }, []);

  const handleUserEdition = (identifier) => {
    setOpen(true);
    if (identifier.target.name === "create") {
      setUser({
        userId: "",
        name: "",
        email: "",
        phone: "",
        mobilePhone: "",
        password: "",
        confirmPassword: "",
        role: "",
        active: true,
      });
      setModalTitle("Criar novo Usuário");
      setIsNewUser("new");
    } else {
      setModalTitle("Editar Usuário");
      getUserById(identifier.target.id);
      setIsNewUser(identifier?.target.id);
    }
  };
  const handleClose = () => {
    setOpen(false);
    setErrors({});
    setUser({
      userId: "",
      name: "",
      email: "",
      phone: "",
      mobilePhone: "",
      password: "",
      confirmPassword: "",
      role: "",
      active: true,
    });
  };

  const handleSubmit = async (user) => {
    event.preventDefault();
    const data = {
      companyId,
      name: user?.name,
      email: user?.email,
      password: user?.password,
      confirmPassword: user?.confirmPassword,
      role: user?.role,
      active: user?.active,
      homePhone: user?.homePhone,
      mobilePhone: user?.mobilePhone,
    };
    if (isNewUser == "new") {
      validationSchema
        .validate(data, { abortEarly: false })
        .then(() => {
          setErrors({});

          openDialogBox({
            message: `Confirma a criação deste usuário?`,
            callback: async () => {
              try {
                openBackdrop(true, "Criando usuário");
                await userUseCases.register(data);
                setFeedbackSnackbar({
                  isOpen: true,
                  message: "Usuário criado com sucesso!",
                  type: "success",
                });
                await getUsers();
              } catch (error) {
                setFeedbackSnackbar({
                  isOpen: true,
                  message: "Erro ao registrar usuário: " + error.response.data,
                  type: "error",
                });
              } finally {
                openBackdrop(false);
                setOpen(false);
              }
            },
          });
        })
        .catch((errors) => {
          // Mostrar mensagens de erro para cada campo
          setErrors(
            errors.inner.reduce((acc, error) => {
              acc[error.path] = error.message;
              return acc;
            }, {})
          );
        });
    } else {
      const fieldsToValidate = ["name", "email", "homePhone", "mobilePhone", "role"];

      if (data.password || data.confirmPassword) {
        fieldsToValidate.push("password", "confirmPassword");
      }

      const editErrors = (
        await Promise.all(
          fieldsToValidate.map((field) =>
            validationSchema
              .validateAt(field, data)
              .then(() => null)
              .catch((error) => error)
          )
        )
      ).filter((error) => error !== null);

      if (editErrors.length > 0) {
        return setErrors(
          editErrors.reduce((acc, error) => {
            acc[error.path] = error.message;
            return acc;
          }, {})
        );
      }

      setErrors({});

      openDialogBox({
        message: `Deseja mesmo editar este usuário?`,
        callback: async () => {
          try {
            openBackdrop(true, "Editando usuário");
            await userUseCases.updateUser(user.id, data);
            setFeedbackSnackbar({
              isOpen: true,
              message: "Usuário editado com sucesso!",
              type: "success",
            });
            await getUsers();
          } catch (error) {
            setFeedbackSnackbar({
              isOpen: true,
              message: "Erro ao editar. Revise os dados inseridos",
              type: "error",
            });
          } finally {
            openBackdrop(false);
            handleClose();
          }
        },
      });
    }
  };

  const handleDelete = (userId) => {
    openDialogBox({
      message: `Deseja mesmo deletar este usuário?`,
      callback: async () => {
        try {
          openBackdrop(true, "Excluindo usuário");
          await userUseCases.deleteUser(userId);
          await getUsers();
          setFeedbackSnackbar({
            isOpen: true,
            message: "Usuário deletado com sucesso!",
            type: "success",
          });
        } catch (error) {
          setFeedbackSnackbar({
            isOpen: true,
            message: "Erro ao deletar usuário: " + error.message,
            type: "error",
          });
        } finally {
          openBackdrop(false);
          setOpen(false);
        }
      },
    });
  };

  const handleChangeSwitch = async (userId, isChecked) => {
    setUsers((prevUsers) =>
      prevUsers.map((user) => {
        if (user.id === userId) {
          return { ...user, active: isChecked };
        } else {
          return user;
        }
      })
    );
    try {
      // Atualizar o usuário na API
      await userUseCases.updateUser(userId, { active: isChecked });

      // Feedback de sucesso
      setFeedbackSnackbar({
        isOpen: true,
        message: isChecked ? "Usuário ativado com sucesso!" : "Usuário desativado com sucesso!",
        type: "success",
      });

      // Atualizar a lista de usuários, se necessário
      await getUsers();
    } catch (error) {
      // Tratamento de erros
      setFeedbackSnackbar({
        isOpen: true,
        message: "Erro ao atualizar usuário: " + error.response.data,
        type: "error",
      });
    }
  };

  const handleSearch = (searchValue) => {
    if (searchValue === null || searchValue === undefined || searchValue.trim() === "") {
      // Mostrar todos os usuários
      setFilteredUsers(users);
    } else {
      // Filtrar usuários por qualquer campo de users (nome e email)
      handleSearchTable(searchValue, users, setFilteredUsers);
    }
  };
  const handlePhoneChange = (event) => {
    const { name, value } = event.target;
    setUser({ ...user, [name]: value });
  };

  return (
    <Container>
      <Box sx={{ display: "flex", gap: 2, mb: 4, alignItems: "center" }}>
        <Text sx={styles.title}>Usuários cadastrados</Text>
        <Button sx={styles.ButtonAdd} onClick={handleUserEdition} name="create">
          Adicionar novo usuário <AddIcon />
        </Button>
      </Box>
      <TextField
        sx={styles.searchField}
        label="Buscar por nome"
        onChange={(e) => handleSearch(e.target.value)}
      />
      <Paper elevation={2}>
        <Table>
          <TableHead sx={styles.TableHead}>
            <TableRow sx={styles.TableRow}>
              <TableCell sx={[styles.TableCell, { fontWeight: 600, fontSize: 18 }]} align="center">
                Nome
              </TableCell>
              <TableCell sx={[styles.TableCell, { fontWeight: 600, fontSize: 18 }]} align="center">
                Email
              </TableCell>
              <TableCell sx={[styles.TableCell, { fontWeight: 600, fontSize: 18 }]} align="center">
                Status
              </TableCell>
              <TableCell sx={[styles.TableCell, { fontWeight: 600, fontSize: 18 }]} align="center">
                Açoes
              </TableCell>
            </TableRow>
          </TableHead>
          {filteredUsers?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((u) => (
            <TableBody key={u}>
              <TableCell sx={styles.TableCell}>{u?.name}</TableCell>
              <TableCell sx={styles.TableCell}>{u?.email}</TableCell>
              <TableCell sx={[styles.TableCell, { width: 200 }]}>
                {u.active ? "Ativo" : "Inativo"}
                <Switch
                  checked={u?.active}
                  onChange={(e) => handleChangeSwitch(u.id, e.target.checked)}
                  inputProps={{ "aria-label": "controlled" }}
                />
              </TableCell>
              <TableCell sx={styles.TableCell} align="center">
                <Button sx={styles.Button} id={u.id} name="edit" onClick={handleUserEdition}>
                  Editar
                </Button>
              </TableCell>
            </TableBody>
          ))}
        </Table>
      </Paper>
      <Stack spacing={1}>
        <TablePagination
          component="div"
          count={filteredUsers?.length || 0}
          page={page}
          onPageChange={(event, newPage) => setPage(newPage)}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={(event) => setRowsPerPage(parseInt(event.target.value, 10))}
          labelRowsPerPage="Usuários por página:"
          rowsPerPageOptions={[10, 15, 20]}
        />
      </Stack>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styles.Modal}>
          <Text
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ marginBlock: 2, fontSize: 20 }}
          >
            {modalTitle}
          </Text>

          <FormControl>
            {user && (
              <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    sx={styles.field}
                    name="name"
                    type="text"
                    label="Nome *"
                    value={user.name}
                    onChange={(e) => setUser({ ...user, name: e.target.value })}
                    error={!!errors.name}
                    helperText={errors.name}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    sx={styles.field}
                    name="email"
                    type="email"
                    label="Email *"
                    value={user.email}
                    onChange={(e) => setUser({ ...user, email: e.target.value })}
                    error={!!errors.email}
                    helperText={errors.email}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputMask
                    name="homePhone"
                    mask="(99) 9999-9999"
                    disabled={false}
                    onChange={handlePhoneChange}
                    maskChar=" "
                    type="text"
                    value={user?.homePhone || ""}
                    InputLabelProps={{ shrink: true }}
                    sx={styles.field}
                  >
                    {() => (
                      <TextField
                        sx={styles.field}
                        name="homePhone"
                        label="Telefone"
                        error={!!errors.homePhone}
                        helperText={errors.homePhone}
                      />
                    )}
                  </InputMask>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputMask
                    name="mobilePhone"
                    mask="(99) 99999-9999"
                    disabled={false}
                    onChange={handlePhoneChange}
                    maskChar=" "
                    type="text"
                    value={user?.mobilePhone || ""}
                    InputLabelProps={{ shrink: true }}
                    sx={styles.field}
                  >
                    {() => (
                      <TextField
                        sx={styles.field}
                        name="mobilePhone"
                        label="Celular"
                        error={!!errors.mobilePhone}
                        helperText={errors.mobilePhone}
                      />
                    )}
                  </InputMask>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    sx={styles.field}
                    name="password"
                    type="password"
                    label="Senha *"
                    value={user?.password || ""}
                    onChange={(e) => setUser({ ...user, password: e.target.value })}
                    error={!!errors.password}
                    helperText={errors.password}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    sx={styles.field}
                    name="confirmPassword"
                    type="password"
                    label="Confirmar Senha *"
                    value={user?.confirmPassword || ""}
                    onChange={(e) => setUser({ ...user, confirmPassword: e.target.value })}
                    error={!!errors.confirmPassword}
                    helperText={errors.confirmPassword}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel>Categoria do usuário *</InputLabel>
                    <Select
                      sx={styles.field}
                      name="role"
                      value={user.role}
                      label="Grupo de Usuário *"
                      onChange={(e) => setUser({ ...user, role: e.target.value })}
                      error={!!errors.role}
                      helperText={errors.role}
                    >
                      {userGroups.map((group, i) => (
                        <MenuItem key={i} value={group.value}>
                          {group.label}
                        </MenuItem>
                      ))}
                    </Select>
                    {errors.role && (
                      <FormHelperText
                        sx={{
                          backgroundColor: "#EFF2F4",
                          marginBlock: 0.1,
                          marginInline: 0.1,
                          paddingX: 1,
                          paddingY: 0.25,
                        }}
                        error={!!errors.role}
                      >
                        {errors.role}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControlLabel
                    sx={{
                      backgroundColor: "#EFF2F4",
                      marginBlock: 0.1,
                      borderRadius: 1,
                      marginInline: 0.1,
                      display: "flex",
                      alignItems: "center",
                      height: " 100%",
                      border: "1px solid #0000003b",
                    }}
                    required
                    control={
                      <Switch
                        checked={user.active}
                        onChange={(e) => setUser({ ...user, active: e.target.checked })}
                      />
                    }
                    label="Usuário ativo?"
                  />
                </Grid>
              </Grid>
            )}
          </FormControl>
          <Box sx={styles.ButtonGroup}>
            <Box>
              {user.id && (
                <Button
                  variant="outlined"
                  sx={styles.DeleteButton}
                  onClick={() => handleDelete(user.id)}
                >
                  Deletar usuário
                </Button>
              )}
            </Box>
            <Box sx={{ display: "flex", gap: 2 }}>
              <Button sx={styles.CancelButton} variant="contained" onClick={handleClose}>
                Cancelar
              </Button>
              <Button sx={styles.Button} variant="contained" onClick={() => handleSubmit(user)}>
                Salvar
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </Container>
  );
};
const styles = {
  TableHead: {
    justifyContent: "flex-start",
    fontWeight: 500,
    backgroundColor: "#EFF2F4",
  },
  TableCell: {
    fontSize: "0.9rem",
    textAlign: "left",
    backgroundColor: "#EFF2F4",
    color: "#021148",
    fontFamily: "Montserrat",
  },
  TableRow: {
    backgroundColor: "#EFF2F4",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.02)",
    },
    height: "70px",
  },
  Actions: {
    "&:hover": {
      color: "#0e4292",
    },
  },
  Button: {
    background: "#081445",
    paddingInline: 4,
    paddingBlock: 1,
    color: "#FFF",
    "&:hover": {
      backgroundColor: "#0e3279",
    },
  },
  ButtonGroup: { display: "flex", gap: 1, marginTop: 2, justifyContent: "space-between" },
  ButtonAdd: {
    background: "#1341a1",
    padding: 0,
    color: "#FFF",
    paddingInline: 4,
    borderRadius: 20,
    height: 48,
    "&:hover": {
      backgroundColor: "#1341a1",
    },
  },
  title: {
    fontFamily: "Montserrat, sans-serif",
    fontSize: 32,
    fontWeight: "bold",
    color: "#242424",
  },
  Modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    bgcolor: "background.paper",
    border: "1px solid #ccc",
    borderRadius: 2,
    boxShadow: 24,
    p: 4,
  },
  switch: {
    width: 34,
    height: 20,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      "&.Mui-checked": {
        transform: "translateX(14px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor: "#0e4292",
          opacity: 1,
          border: "none",
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#0e4292",
        border: "6px solid #fff",
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "none",
      width: 20,
      height: 20,
      margin: 0,
      backgroundColor: "#fff",
      border: "1px solid #E9E9EA",
    },
    "& .MuiSwitch-track": {
      borderRadius: 20 / 2,
      opacity: 1,
      backgroundColor: "#E9E9EA",
      boxSizing: "border-box",
    },
  },
  field: {
    backgroundColor: "#EFF2F4",
    width: "100%",
  },
  searchField: {
    backgroundColor: "#EFF2F4",
    width: "100%",
    margin: 0,
    border: "none",
  },
  DeleteButton: {
    border: "1px solid red",
    color: "red",
  },
  CancelButton: {
    backgroundColor: "#EFF2F4",
    color: "black",
  },
};
