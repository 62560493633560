import React from "react";
import { makeLegislationCategoryUseCases } from "~/main/factories/use-cases/legislation-category";
import LegislationCategory from "~/presentation/views/Admin/LegislationCategory/LegislationCategory";

export const makeLegislationCategoryTable = (params) => {
  return (
    <LegislationCategory
      {...params}
      legislationCategoryUseCases={makeLegislationCategoryUseCases()}
    />
  );
};
