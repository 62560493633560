import React, { useContext, useEffect, useState } from "react";
import HeaderForm from "../../components/HeaderForm/HeaderForm";
import AnswerForm from "./Form";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import { ref } from "yup";

function CreateEdit({
  data,
  onCloseForm,
  refreshData,
  answerUseCases,
  fetchSelectData,
  cnaes,
  itemsAnswer,
  categories,
  terms,
}) {
  const { openBackdrop } = useContext(BackdropContext);
  const { setFeedbackSnackbar } = useContext(SnackbarContext);
  const isCreate = !data || data.isCopy; //caso seja copiar dados
  const [initialValues, setInitialValues] = useState();
  const needFetchOptions =
    cnaes.length === 0 || terms.length === 0 || itemsAnswer.length === 0 || categories.length === 0;

  useEffect(() => {
    if (needFetchOptions) {
      fetchSelectData();
    }
  }, []);

  useEffect(() => {
    const fetch = async () => {
      openBackdrop(true, "Carregando informação");
      const result = await answerUseCases.getById(data.id);
      setInitialValues(result);
      openBackdrop(false);
    };
    if (data?.id) {
      fetch();
    } else {
      setInitialValues({
        id: 0,
        gtAnswerName: "",
        description: "",
        typeTax: null,
        isSimple: true,
        companyType: [],
        categories: [],
        cityId: null,
        cnaes: [],
        gtAnswerItems: [],
      });
    }
  }, [data]);

  const handleSubmit = async (values) => {
    openBackdrop(true, `${isCreate ? "Criando" : "Editando"} resposta`);
    try {
      if (isCreate) {
        await answerUseCases.createAnswer(values);
      } else {
        await answerUseCases.update(values);
      }
      setFeedbackSnackbar({
        isOpen: true,
        message: `Resposta ${isCreate ? "criada" : "editada"} com sucesso!`,
        type: "success",
      });
      onCloseForm();
      await refreshData();
    } catch (error) {
      setFeedbackSnackbar({
        isOpen: true,
        message: `Erro. ${error?.response?.data}`,
        type: "error",
      });
    } finally {
      openBackdrop(false);
    }
  };

  return (
    <>
      <HeaderForm title={`${isCreate ? "Criar" : "Editar"} resposta`} backFnc={onCloseForm} />
      {initialValues && (
        <AnswerForm
          initialValues={initialValues}
          handleSubmit={handleSubmit}
          cnaes={cnaes}
          itemsAnswer={itemsAnswer}
          categories={categories}
          terms={terms}
        />
      )}
    </>
  );
}

export default CreateEdit;
