import * as Yup from "yup";
import { validarCNPJ } from "~/presentation/functions/cnpjValidator";

const validation = Yup.object({
  legalName: Yup.string().required("Campo Obrigatório!"),
  cpfCnpj: Yup.string()
    .required("Campo obrigatório!")
    .test("cpfCnpj", "CNPJ inválido", (value) => value && validarCNPJ(value)),
  number: Yup.string().max(20, "Digite um Número válido até 20 caracteres"),
  homePhone: Yup.string("Campo obrigatório!")
    .min(10, "Digite um número válido")
    .required("Campo obrigatório!"),
  email: Yup.string().email("Digite um email válido"),
});

export default validation;
