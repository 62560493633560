import React, { useState, useEffect, useContext, useRef } from "react";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
// components
import Table from "~/presentation/components/Table";
import TableActions from "~/presentation/components/TableActions";
import PageListHeader from "../components/PageListHeader";
//functions
import triggerInputChange from "~/presentation/hooks/triggerInputChange";
import { DialogContext } from "~/presentation/providers/DialogProvider";
import { Container } from "@mui/material";
import { parseISO, format } from "date-fns";
import ptBR from "date-fns/locale/pt-BR";
import CreateEdit from "./CreateEdit/CreateEdit";

const columns = [
  { name: "publishDate", label: "Data da Publicação" },
  { name: "title", label: "Título" },
  { name: "actions", label: "Ações", options: { sortable: "false" } },
];

const FAQ = ({ history, explanatoryVideoUseCases }) => {
  const { openBackdrop } = useContext(BackdropContext);
  const { setFeedbackSnackbar } = useContext(SnackbarContext);
  const { openDialogBox } = useContext(DialogContext);

  const [explanatoryVideos, setExplanatoryVideos] = useState([]);

  //Abrir forms criar / editar
  const [openForm, setOpenForm] = useState(false);
  const [formProps, setFormProps] = useState(false);

  const hiddenInputRef = useRef();
  const searchFieldRef = useRef();

  const handleOpenForm = (data = null) => {
    setOpenForm(true);
    setFormProps(data);
  };

  const handleCloseForm = () => {
    setOpenForm(false);
    setFormProps(null);
  };

  const fetchData = async () => {
    openBackdrop(true, "Carregando dados da tabela");
    const fetchedContents = await explanatoryVideoUseCases.get();

    const formattedContents = fetchedContents.map((video) => ({
      ...video,
      publishDate: format(parseISO(video.publishDate), "dd/MM/yyyy", { locale: ptBR }),
      actions: (
        <TableActions
          handleDelete={() => deleteDialog(video.id)}
          handleEdit={() => handleOpenForm(video)}
        />
      ),
    }));

    setExplanatoryVideos(formattedContents);
    openBackdrop(false);
  };

  const handleDelete = async (id) => {
    openBackdrop(true, "Excluindo vídeo explicativo");
    try {
      await explanatoryVideoUseCases.delete(id);
      setFeedbackSnackbar({
        isOpen: true,
        message: "Vídeo excluído com successo!",
        type: "success",
      });
      setExplanatoryVideos((prevVideos) => prevVideos.filter((video) => video.id !== id));
      cleanSearch();
    } catch (error) {
      setFeedbackSnackbar({
        isOpen: true,
        message: "Erro ao tentar excluir vídeo:" + error,
        type: "error",
      });
    }

    openBackdrop(false);
  };

  const deleteDialog = (id) => {
    openDialogBox({
      message: `Deseja mesmo excluir este vídeo?`,
      callback: () => handleDelete(id),
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSearch = (value) => triggerInputChange(hiddenInputRef.current, value);
  const cleanSearch = () => {
    triggerInputChange(hiddenInputRef.current);
    triggerInputChange(searchFieldRef.current);
  };

  return (
    <Container maxWidth="100%">
      {openForm ? (
        <CreateEdit
          data={formProps}
          onCloseForm={handleCloseForm}
          refreshData={fetchData}
          explanatoryVideoUseCases={explanatoryVideoUseCases}
        />
      ) : (
        <>
          <PageListHeader
            title="Conteúdos explicativos"
            titleAccess="Novo vídeo"
            searchFieldRef={searchFieldRef}
            handleAdd={() => handleOpenForm()}
            handleSearch={handleSearch}
          />
          <Table
            searchRef={hiddenInputRef}
            columns={columns}
            data={explanatoryVideos}
            title="Vídeos"
          />
        </>
      )}
    </Container>
  );
};

export default FAQ;
