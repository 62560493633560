import React, { useContext, useEffect, useState } from "react";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import HeaderForm from "../../components/HeaderForm/HeaderForm";
import IssAnswerForm from "./Form";

function CreateEdit({
  data,
  onCloseForm,
  refreshData,
  makeIssAnswerUseCases,
  fetchSelectOptions,
  groups,
  typesAnswerUseCases,
}) {
  const { openBackdrop } = useContext(BackdropContext);
  const { setFeedbackSnackbar } = useContext(SnackbarContext);
  const isCreate = !data;

  //Dados forms
  const [initialValues, setInitialValues] = useState();

  //itens de Resposta
  const [itemsAnswer, setItemsAnswer] = useState([]);

  useEffect(() => {
    const fetch = async () => {
      openBackdrop(true, "Carregando informação");
      const response = await makeIssAnswerUseCases.getIssAnswer(data.id);
      const apiTypeAnswers = await typesAnswerUseCases.getTypesAnswer();
      const initialvalues = {
        id: response.data.id,
        isLegislation: response.data.isLegislation.toString(),
        isDispensedEROS: response.data.isDispensedEROS.toString(),
        isDispensedCPOM: response.data.isDispensedCPOM.toString(),
        cpom: response.data.cpom,
        isSpecificSubstituition: response.data.isSpecificSubstituition.toString(),
        cityId: response.data.cityId,
        cityName: response.data.cityName,
        items: response.data.items,
      };
      setInitialValues(initialvalues);
      setItemsAnswer(apiTypeAnswers);
      openBackdrop(false);
    };

    if (data?.id) {
      fetch();
    } else {
      setInitialValues({
        id: 0,
        isLegislation: true,
        isDispensedEROS: true,
        isDispensedCPOM: false,
        cpom: 0,
        isSpecificSubstituition: true,
        cityId: 0,
        cityName: "",
        items: [],
      });
    }
  }, [data]);

  useEffect(() => {
    if (groups.length === 0) {
      fetchSelectOptions();
    }
  }, []);

  const handleSubmit = async (values) => {
    openBackdrop(true, `${isCreate ? "Cadastrando" : "Editando"} resposta`);
    try {
      if (isCreate) {
        await makeIssAnswerUseCases.createIssAnswer(values);
      } else {
        await makeIssAnswerUseCases.updateIssAnswer(values);
      }
      setFeedbackSnackbar({
        isOpen: true,
        message: `Resposta ${isCreate ? "cadastrada" : "editada"} com sucesso!`,
        type: "success",
      });
      onCloseForm();
      await refreshData();
    } catch (err) {
      setFeedbackSnackbar({
        isOpen: true,
        message: "Erro. " + err?.response?.data,
        type: "error",
      });
    } finally {
      openBackdrop(false);
    }
  };

  const deleteItemAnswer = async (id) => {
    try {
      openBackdrop(true, "Deletando item");
      await makeIssAnswerUseCases.deleteIssAnswerItem(id);
      setFeedbackSnackbar({
        isOpen: true,
        message: "Item deletado com sucesso!",
        type: "success",
      });
      setItemsAnswer((prev) => prev.filter((item) => item.id !== id));
    } catch (err) {
      setFeedbackSnackbar({
        isOpen: true,
        message: "Erro ao deletar o item",
        type: "error",
      });
    } finally {
      openBackdrop(false);
    }
  };

  return (
    <>
      <HeaderForm title={`${isCreate ? "Cadastrar" : "Editar"} Resposta`} backFnc={onCloseForm} />
      {initialValues && (
        <IssAnswerForm
          initialValues={initialValues}
          handleSubmit={handleSubmit}
          itemsAnswer={itemsAnswer}
          groups={groups}
          handleDelete={deleteItemAnswer}
        />
      )}
    </>
  );
}

export default CreateEdit;
