import React, { useState, useEffect, useContext, useRef } from "react";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
// // components
import Table from "~/presentation/components/Table";
import TableActions from "~/presentation/components/TableActions";
import PageListHeader from "../components/PageListHeader";
// //functions
import triggerInputChange from "~/presentation/hooks/triggerInputChange";
import { DialogContext } from "~/presentation/providers/DialogProvider";
import { Container } from "@mui/material";
import CreateEdit from "./CreateEdit/CreateEdit";

const IssAnswer = ({ makeIssAnswerUseCases, typesAnswerUseCases }) => {
  const { openBackdrop } = useContext(BackdropContext);
  const { setFeedbackSnackbar } = useContext(SnackbarContext);
  const { openDialogBox } = useContext(DialogContext);

  //data
  const [issAnswers, setIssAnswers] = useState([]);

  //select data options
  const [groups, setGroups] = useState([]);

  //Abrir forms criar / editar
  const [openForm, setOpenForm] = useState(false);
  const [formProps, setFormProps] = useState(false);

  const hiddenInputRef = useRef();
  const searchFieldRef = useRef();

  const handleOpenForm = (data = null) => {
    setOpenForm(true);
    setFormProps(data);
  };

  const handleCloseForm = () => {
    setOpenForm(false);
    setFormProps(null);
  };

  const fetchData = async () => {
    openBackdrop(true, "Carregando dados da tabela");

    const { data = [] } = await makeIssAnswerUseCases.getIssAnswers();
    data.forEach((answer) => {
      answer.actions = (
        <TableActions
          // handleDelete={() => deleteDialog(answer.id)}
          handleEdit={() => handleOpenForm(answer)}
        />
      );
    });
    setIssAnswers(data);
    openBackdrop(false);
  };

  const cpomLabel = (value) => (value === 0 ? "Nenhum" : value === 1 ? "CPOM 1" : "CPOM 2");

  const columns = [
    {
      name: "cityName",
      label: "Nome da Cidade",
    },
    {
      name: "isLegislation",
      label: "Legislaçao",
      options: {
        filter: false,
        customBodyRender: (value) => (value === true ? "Sim" : "Não"),
      },
    },
    {
      name: "isSpecificSubstituition",
      label: "Substituição Específica",
      options: {
        filter: false,
        customBodyRender: (value) => (value === true ? "Sim" : "Não"),
      },
    },
    {
      name: "isDispensedEROS",
      label: "ERO Dispensado",
      options: {
        filter: false,
        customBodyRender: (value) => (value === true ? "Sim" : "Não"),
      },
    },
    {
      name: "isDispensedCPOM",
      label: "CPOM Dispensado",
      options: {
        filter: false,
        customBodyRender: (value) => (value === true ? "Sim" : "Não"),
      },
    },
    {
      name: "CPOM",
      label: "CPOM",
      options: {
        filter: false,
        customBodyRender: (value) => cpomLabel(value),
      },
    },
    {
      name: "actions",
      label: "Ações",
      options: { sortable: "false" },
    },
  ];

  const handleDelete = async (id) => {
    openBackdrop(true, "Excluindo resposta");
    try {
      await makeIssAnswerUseCases.deleteIssAnswer(id);
      setIssAnswers((prev) => prev.filter((item) => item.id !== id));
      cleanSearch();
    } catch (error) {
      setFeedbackSnackbar({
        isOpen: true,
        message: "Erro ." + error?.response?.data,
        type: "error",
      });
    }
    openBackdrop(false);
  };

  const deleteDialog = (id) => {
    openDialogBox({
      message: `Deseja mesmo excluir esta resposta?`,
      callback: () => handleDelete(id),
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSearch = (value) => triggerInputChange(hiddenInputRef.current, value);

  const cleanSearch = () => {
    triggerInputChange(hiddenInputRef.current);
    triggerInputChange(searchFieldRef.current);
  };

  const fetchSelectOptions = async () => {
    const groupsResponse = await makeIssAnswerUseCases.getGroups();
    setGroups(groupsResponse.data);
  };

  return (
    <Container maxWidth="100%">
      {openForm ? (
        <CreateEdit
          data={formProps}
          onCloseForm={handleCloseForm}
          refreshData={fetchData}
          makeIssAnswerUseCases={makeIssAnswerUseCases}
          fetchSelectOptions={fetchSelectOptions}
          groups={groups}
          typesAnswerUseCases={typesAnswerUseCases}
        />
      ) : (
        <>
          <PageListHeader
            title="Resposta do ISS"
            titleAccess="Nova resposta"
            searchFieldRef={searchFieldRef}
            // handleAdd={() => handleOpenForm()}
            handleSearch={handleSearch}
          />
          <Table
            searchRef={hiddenInputRef}
            columns={columns}
            data={issAnswers}
            title="Respostas do Iss"
          />
        </>
      )}
    </Container>
  );
};

export default IssAnswer;
