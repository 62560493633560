import React, { useContext, useState } from "react";
import {
  Box,
  Container,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  Tooltip,
  Typography,
} from "@mui/material";
import RButton from "~/presentation/views/Reinf/components/Common/Buttons/RButton";
import { TableRow } from "@mui/material";
import { DeleteOutline, EditNote } from "@mui/icons-material";
import ModalCreateEdit from "./ModalCreateEdit";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import { DialogContext } from "~/presentation/providers/DialogProvider";
import ModalContainer from "~/presentation/views/Reinf/components/ModalContainer/ModalContainer";
import { formatCpfCnpj } from "~/presentation/views/Reinf/Utils/utilsReinf";

function Establishments({
  matrizCompanyId,
  refreshData,
  establishmentsData,
  setEstablishmentsData,
  establishmentsUseCases,
}) {
  const { setFeedbackSnackbar } = useContext(SnackbarContext);
  const { openDialogBox } = useContext(DialogContext);

  //data para modal
  const [establishment, setEstablishment] = useState([]);

  //tabela
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  // Calcular os índices de início e fim com base na página e nas linhas por página
  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const currentData =
    establishmentsData.length > 0 ? establishmentsData.slice(startIndex, endIndex).reverse() : [];

  //modal edit/creat
  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = (item) => {
    setEstablishment(item);
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleDelete = async (id) => {
    openDialogBox({
      message: "Deseja eliminar permanentemente este Estabelecimento?",
      callback: () => {
        establishmentsUseCases
          .deleteEstablishmentById(id)
          .then(() => {
            // Remover da tabela
            setEstablishmentsData((prev) => prev.filter((item) => item.id !== id));
            setFeedbackSnackbar({
              isOpen: true,
              message: "Estabelecimento eliminado com sucesso!",
              type: "success",
            });
          })
          .catch((error) => {
            setFeedbackSnackbar({
              isOpen: true,
              message: `Erro ao excluir Estabelecimento: ${error?.message}`,
              type: "error",
            });
          });
      },
    });
  };

  return (
    <>
      <Container maxWidth="100%" sx={{ marginBottom: 5 }}>
        <ModalContainer open={openModal} onClose={handleCloseModal}>
          <ModalCreateEdit
            matrizCompanyId={matrizCompanyId}
            data={establishment}
            closeModal={handleCloseModal}
            establishmentsUseCases={establishmentsUseCases}
            refreshData={refreshData}
          />
        </ModalContainer>
        <Stack direction="row" spacing={2} sx={{ paddingBlock: 2 }}>
          <Typography style={styles.Title}>Estabelecimentos</Typography>
          <RButton sx={{ width: "120px", p: 1 }} onClick={handleOpenModal}>
            Incluir
          </RButton>
        </Stack>
        <Box sx={{ borderRadius: 3, overflow: "hidden" }}>
          <Table>
            <TableHead>
              <TableRow sx={styles.HeadTableRow}>
                <TableCell sx={styles.TableCellHead} align="center">
                  CNO/CNPJ do Estabelecimento
                </TableCell>
                <TableCell sx={styles.TableCellHead} align="center">
                  Nome do Estabelecimento
                </TableCell>
                <TableCell sx={styles.TableCellHead} align="center">
                  Código da Unidade Gestora
                </TableCell>
                <TableCell sx={styles.TableCellHead} align="center">
                  Ações
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {currentData.length > 0 ? (
                currentData.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell sx={styles.TableCell}>{formatCpfCnpj(item.cnoCnpj || "-")}</TableCell>
                    <TableCell sx={styles.TableCell}>{item.name}</TableCell>
                    <TableCell sx={styles.TableCell}>{item.codeUG}</TableCell>
                    <TableCell sx={styles.TableCellIcons}>
                      <Tooltip title="Editar Estabelecimento">
                        <EditNote sx={styles.icon} onClick={() => handleOpenModal(item)} />
                      </Tooltip>
                      <Tooltip title="Deletar Estebelecimento">
                        <DeleteOutline sx={styles.icon} onClick={() => handleDelete(item.id)} />
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell sx={{ ...styles.TableCell, padding: "10px" }} colSpan="100%">
                    <p style={{ textAlign: "center" }}>Não existe informações disponíveis...</p>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          {establishmentsData?.length > 4 && (
            <Stack
              spacing={1}
              sx={{ background: "#F2F2F2", paddingRight: 8, borderRadius: "0px 0px 8px 8px" }}
            >
              <TablePagination
                component="div"
                count={establishmentsData?.length || 0}
                page={page}
                onPageChange={(event, newPage) => setPage(newPage)}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={(event) => setRowsPerPage(parseInt(event.target.value, 10))}
                labelRowsPerPage="Informações por página:"
                rowsPerPageOptions={[5, 10, 15, 20]}
                sx={{
                  ".MuiTablePagination-selectLabel ": {
                    margin: 0,
                  },
                  ".MuiTablePagination-displayedRows": {
                    margin: 0,
                  },
                }}
              />
            </Stack>
          )}
        </Box>
      </Container>
    </>
  );
}

export default Establishments;

const styles = {
  Title: {
    fontFamily: "Montserrat",
    fontSize: "24px",
    fontWeight: 700,
    color: "#404040",
    textAlign: "left",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    userSelect: "none",
  },
  icon: {
    fontSize: "2rem",
    cursor: "pointer",
    ":hover": {
      color: "#1341a1",
    },
  },
  HeadTableRow: {},
  //LINHA TÍTULOS DAS COLUANS
  TableCellHead: {
    backgroundColor: "#1341A1",
    fontFamily: "Montserrat",
    textAlign: "center",
    color: "#ffffff",
    fontSize: 13,
    padding: "10px",
    fontWeight: 400,
    position: "relative",
    //DIVISOR COLUNAS HEAD
    "&:not(:first-child)::after": {
      content: '""',
      display: "block",
      position: "absolute",
      top: "10%",
      bottom: "10%",
      left: 0,
      width: "3px",
      backgroundColor: "#142F68",
    },
  },
  //CADA LINHA (NOTA)
  TableCell: {
    fontFamily: "Montserrat ",
    fontSize: 14,
    padding: "6px",
    color: "#000000",
    textAlign: "center",
    backgroundColor: "#D9D9D9",
  },
  TableCellIcons: {
    padding: "6px",
    backgroundColor: "#D9D9D9",
    display: "flex",
    gap: 1,
    justifyContent: "center",
  },
  containerFields: {
    backgroundColor: "#EFF2F4",
    borderRadius: 2,
    padding: "20px 20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
  },
  titleModal: {
    fontSize: 18,
    fontWeight: 500,
    width: "60%",
    color: "#021148",
    fontFamily: "Montserrat, sans-serif",
    alignItems: "center",
  },
};
