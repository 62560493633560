import React from "react";
import { makeAnswerUseCases } from "~/main/factories/use-cases/answers";
import { makeCnaesUseCases } from "~/main/factories/use-cases/cnaes";
import { makeCompanyUseCases } from "~/main/factories/use-cases/company";
import { makeItemAnswerUseCases } from "~/main/factories/use-cases/item-answer";
import { makeTermUseCases } from "~/main/factories/use-cases/term";
import Answer from "~/presentation/views/Admin/Answer/Answer";

export const makeAnswerTable = (params) => (
  <Answer
    {...params}
    answerUseCases={makeAnswerUseCases()}
    itemAnswerUseCases={makeItemAnswerUseCases()}
    companyUseCases={makeCompanyUseCases()}
    cnaesUseCases={makeCnaesUseCases()}
    termUseCases={makeTermUseCases()}
  />
);
