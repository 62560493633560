import React, {useContext, useEffect, useRef, useState} from "react";
import { Formik, Form } from "formik";

import {
  SelectQuestion,
  Button,
  SimulatorResultCard,
} from "~/presentation/views/GTFacil/Simulator/components";
import { SimulatorContext } from "~/presentation/views/GTFacil/Simulator/context/simulator-context";
import { floatToReais } from "~/presentation/utils/reais-parser";
import { reportMapper } from "./reportMapper";
import {Grid} from "@mui/material";

// No momento do calculo verificar a natureza juridica do contratante
// Definir a regra baseado na natureza juridica
// regras no excel

const retentionAliquotOptions = () => {
  const options = [
    {
      value: 0.0585,
      code: 6147,
      label:
        "Mercadorias e bens em geral; serviço com emprego de materiais, de transporte de cargas, hospitalares e de auxílio e diagnóstico.",
    },
    {
      value: 0.022,
      code: 8767,
      label:
        "Mercadorias ou serviços beneficiados com isenção, não incidência ou alíquota zero de PIS/Pasep e COFINS (conforme destacado na NF)",
    },
    {
      value: 0.0489,
      code: 9060,
      label: "Derivados do petróleo, álcool etílico hidratado e biodisesel",
    },
    {
      value: 0.0124,
      code: 8739,
      label:
        "Derivados do petróleo, álcool etílico hidratado e biodisel adquiridos de distribuidores e comerciantes varejistas",
    },
    {
      value: 0.0705,
      code: 6175,
      label:
        "Passagens aéreas, rodoviárias e demais serviços de transporte de passageiros, inclusive, tarifa de embarque, exceto as relacionadas no código 8850",
    },
    {
      value: 0.034,
      code: 8850,
      label:
        "Transporte internacional de passageiros efetuados por empresas nacionais",
    },
    {
      value: 0.0465,
      code: 8863,
      label:
        "Serviços prestados por associações profissionais ou assemelhadas e cooperativas",
    },
    {
      value: 0.0705,
      code: 6188,
      label:
        "Serviços prestados por bancos, sociedades de crédito, financiamento e investimento, distribuidoras de títulos e valores mobiliários e seguro saúde",
    },
    {
      value: 0.0945,
      code: 6190,
      label: "Demais serviços",
    },
    {
      value: 0,
      code: 0,
      label: "Retenção não agregada",
    },
  ];

  return options.map((r) => {
    if(r.code === 0) return r;
    r.label = `${(r.value * 100).toFixed(2)}% - ${r.code} - ${r.label}`;
    return r;
  });
};

const initialValues = {
  retentionAliquot: retentionAliquotOptions()[0],
  irpj: {value: 0, label: "0%"},
  csll: {value: 0, label: "0%"},
  pisPasep: {value: 0, label: "0%"},
  cofins: {value: 0, label: "0%"},
};

export const IrpjCsllPisPasepCofinsPJNotSimples = () => {
  const resultCardRef = useRef();
  const { simulatorValue, setInvalidSimulatorValue, handleCacheReport} = useContext(SimulatorContext);
  const [result, setResult] = useState();

  const calculateResult = (values) =>
    simulatorValue * values.retentionAliquot.value;

  const calculateNotAggregatedResult = (values) => {
    const irpj = simulatorValue * values.irpj.value;
    const csll = simulatorValue * values.csll.value;
    const pisPasep = simulatorValue * values.pisPasep.value;
    const cofins = simulatorValue * values.cofins.value;
    return irpj + csll + pisPasep + cofins;
  }

  useEffect(() => {
    if(result) {
      resultCardRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [result]);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values, action) => {
        if (!simulatorValue) {
          setInvalidSimulatorValue(true);
          return;
        }

        const result = values.retentionAliquot.code === 0 ?
          calculateNotAggregatedResult(values)
          : calculateResult(values);

        setResult(floatToReais(result));

        action.setSubmitting(false);

        const response = reportMapper(values, result);

        handleCacheReport(response);

        resultCardRef.current?.scrollIntoView({ behavior: "smooth" });
      }}
    >
      {({ setFieldValue, values }) => (
        <Form>
          <Grid
            container
            gap={2}
            sx={{
              height: "100%",
            }}
          >
            <Grid item xs={12}>
              <SelectQuestion
                vertical
                text="Alíquota da retenção:"
                excerpt="A tabela de retenção (Anexo I da IN 1.234/2012) estabelece o percentual a ser aplicado, correspondente à soma das alíquotas de cada tributo, levando em consideração a natureza do bem fornecido ou do serviço prestado, sendo que os mais comuns se referem à alíquota total de 5,85% (código 6147) e 9,45% (código 6190)."
                onChange={(value) => setFieldValue("retentionAliquot", value)}
                value={values.retentionAliquot.code}
                options={retentionAliquotOptions()}
              />
            </Grid>

            {
              values.retentionAliquot.code === 0 ? (
                <>
                  <Grid item xs={12}>
                    <SelectQuestion
                      text="IRPJ:"
                      value={values.irpj.value}
                      onChange={(value) => setFieldValue("irpj", value)}
                      options={[
                        {
                          value: 0,
                          label: "0%",
                        },
                        {
                          value: 0.0024,
                          label: "0,24%",
                        },
                        {
                          value: 0.012,
                          label: "1,2%",
                        },
                        {
                          value: 0.024,
                          label: "2,4%",
                        },
                        {
                          value: 0.048,
                          label: "4,8%",
                        },
                      ]}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <SelectQuestion
                      text="CSLL:"
                      value={values.csll.value}
                      onChange={(value) => setFieldValue("csll", value)}
                      options={[
                        {
                          value: 0,
                          label: "0%",
                        },
                        {
                          value: 0.01,
                          label: "1%",
                        }
                      ]}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <SelectQuestion
                      text="PIS/Pasep:"
                      value={values.pisPasep.value}
                      onChange={(value) => setFieldValue("pisPasep", value)}
                      options={[
                        {
                          value: 0,
                          label: "0%",
                        },
                        {
                          value: 0.0065,
                          label: "0,65%",
                        }
                      ]}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <SelectQuestion
                      text="COFINS:"
                      value={values.cofins.value}
                      onChange={(value) => setFieldValue("cofins", value)}
                      options={[
                        {
                          value: 0,
                          label: "0%",
                        },
                        {
                          value: 0.03,
                          label: "3%",
                        }
                      ]}
                    />
                  </Grid>
                </>
              ) : null
            }

            <Grid
              item
              xs={12}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Button>Ver Resultado</Button>
            </Grid>

            {result ? (
              <Grid
                item
                xs={12}
                sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
              >
                <SimulatorResultCard
                  componentRef={resultCardRef}
                  title="Valor da retenção do IRPJ, CSLL, PIS/Pasep e COFINS:"
                  value={result}
                  description="O valor da retenção do IR, CSLL, PIS/Pasep e COFINS é aquele que deve ser abatido do valor a ser pago para o contratado."
                />
              </Grid>
            ) : null}
          </Grid>
        </Form>
      )}
    </Formik>
  );
};
