import React, { useState, useEffect, useContext, useRef } from "react";
import AppContext from "~/presentation/AppContext";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
// components
import Table from "~/presentation/components/Table";
import TableActions from "~/presentation/components/TableActions";
// functions
import triggerInputChange from "~/presentation/hooks/triggerInputChange";
import { DialogContext } from "~/presentation/providers/DialogProvider";
import { Container } from "@mui/material";
import PageListHeader from "../components/PageListHeader";
import CreateEdit from "./CreateEdit/CreateEdit";

const columns = [
  {
    name: "gtAnswerItemType",
    label: "Tipo",
    options: {
      filter: false,
      customBodyRender: (value) => value.title,
    },
  },
  { name: "title", label: "Título" },
  { name: "actions", label: "Ações", options: { sortable: "false" } },
];

const ItemAnswer = ({ itemAnswerUseCases, typesAnswerUseCases }) => {
  const context = useContext(AppContext);
  const { openBackdrop } = useContext(BackdropContext);
  const { setFeedbackSnackbar } = useContext(SnackbarContext);
  const { openDialogBox } = useContext(DialogContext);

  //data
  const [itemsAnswer, setItemsAnswer] = useState([]);

  //options
  const [typesAnswer, setTypesAnswer] = useState([]);

  //Abrir forms criar / editar
  const [openForm, setOpenForm] = useState(false);
  const [formProps, setFormProps] = useState(false);

  const hiddenInputRef = useRef();
  const searchFieldRef = useRef();

  const handleOpenForm = (data = null) => {
    setOpenForm(true);
    setFormProps(data);
  };

  const handleCloseForm = () => {
    setOpenForm(false);
    setFormProps(null);
  };

  const fetchData = async () => {
    openBackdrop(true, "Carregando dados da tabela");

    const apiItemsAnswer = await itemAnswerUseCases.getAll();

    const userIsSuperAdmin = context.haveSuperAdminAccess();

    apiItemsAnswer?.forEach((itemAnswer) => {
      if (userIsSuperAdmin) {
        itemAnswer.actions = (
          <TableActions
            handleDelete={() => deleteDialog(itemAnswer.id)}
            handleEdit={() => handleOpenForm(itemAnswer)}
          />
        );
      } else {
        itemAnswer.actions = <TableActions handleEdit={() => handleOpenForm(itemAnswer)} />;
      }
    });
    setItemsAnswer(apiItemsAnswer);
    openBackdrop(false);
  };

  const handleDelete = async (id) => {
    openBackdrop(true, "Excluindo tipo de resposta");
    try {
      await itemAnswerUseCases.delete(id);
      setItemsAnswer((prev) => prev.filter((item) => item.id !== id));
      cleanSearch();
    } catch (error) {
      setFeedbackSnackbar({
        isOpen: true,
        message: "Erro ao excluir tipo de resposta: Missing endpoint.",
        type: "error",
      });
    }
    openBackdrop(false);
  };

  const deleteDialog = (id) => {
    openDialogBox({
      message: `Deseja mesmo excluir este serviço?`,
      callback: () => handleDelete(id),
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSearch = (value) => triggerInputChange(hiddenInputRef.current, value);

  const cleanSearch = () => {
    triggerInputChange(hiddenInputRef.current);
    triggerInputChange(searchFieldRef.current);
  };

  const fetchOptionsData = async () => {
    const apiTypesAnswer = await typesAnswerUseCases.getTypesAnswer();
    setTypesAnswer(apiTypesAnswer);
  };

  return (
    <Container maxWidth="100%">
      {openForm ? (
        <CreateEdit
          data={formProps}
          onCloseForm={handleCloseForm}
          refreshData={fetchData}
          itemAnswerUseCases={itemAnswerUseCases}
          fetchOptionsData={fetchOptionsData}
          typesAnswer={typesAnswer}
        />
      ) : (
        <>
          <PageListHeader
            title="Item de resposta"
            titleAccess="Novo item"
            searchFieldRef={searchFieldRef}
            handleAdd={() => handleOpenForm()}
            handleSearch={handleSearch}
          />
          <Table searchRef={hiddenInputRef} columns={columns} data={itemsAnswer} />
        </>
      )}
    </Container>
  );
};

export default ItemAnswer;
