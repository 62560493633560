import React from "react";
import { useFormikContext } from "formik";

import {
  RadioQuestion,
  SimulatorResultCard,
  MoneyQuestion,
  PercentageQuestion,
} from "~/presentation/views/GTFacil/Simulator/components";

export const HasMaterialExclusion = ({ onChange }) => {
  const { values, setFieldValue } = useFormikContext();
  return (
    <RadioQuestion
      text="Há exclusão de materiais, subempreitadas ou outras parcelas da base de cálculo?"
      onChange={(value) => {
        setFieldValue("hasExclusions", value === "true");
        onChange();
      }}
      value={values.hasExclusions.toString()}
      excerpt="O art. 7º, § 2º, I, da Lei Complementar n° 116/2003, prevê a exclusão da base de cálculo dos materiais fornecidos pelo prestador dos serviços previstos nos itens 7.02 e 7.05 da lista de serviços.
              Por sua vez, o art. 9º, § 2º, do Decreto Lei n° 406/68 estabelece que, para os serviços dos itens 19 e 20 (correspondentes aos atuais 7.02 e 7.05 da lista anexa à LC 116/2003), além da exclusão dos materiais da base de cálculo, é possível a exclusão das subempreitadas já tributadas pelo imposto. Entretanto, é necessário verificar se a legislação do município ratifica e regulamenta tais exclusões.
              Para exclusão de quaisquer outras parcelas é necessário verificar se a legislação do município competente para a cobrança do ISS autoriza."
    />
  );
};

export const MaterialExclusionValue = () => {
  const { values, setFieldValue } = useFormikContext();
  return (
    <MoneyQuestion
      text="Valor da exclusão (autorizada em lei municipal):"
      value={values.exclusionValue}
      onChange={(_event, _maskedValue, floatValue) => {
        setFieldValue("exclusionValue", floatValue);
      }}
    />
  );
};

export const BaseRetentionCalcValue = ({ value }) => {
  return (
    <MoneyQuestion
      text="Base de cálculo da retenção do ISS:"
      excerpt="A base de cálculo é o valor bruto da nota fiscal excluídos os valores dos materiais, subempreitadas ou outras parcelas."
      value={value}
      disabled
      allowNegative
    />
  );
};

export const RetentionAliquotPercentage = () => {
  const { values, setFieldValue } = useFormikContext();
  return (
    <PercentageQuestion
      name="retentionAliquotPercentage"
      text="Qual a alíquota da retenção?"
      excerpt="A alíquota de retenção é determinada pela lei do município competente para a cobrança do imposto, que deve respeitar as alíquotas mínima e máxima correspondentes a 2% e 5%, respectivamente, conforme disposição da Emenda Constitucional nº 37/2002 e Lei Complementar n° 116/2003."
      value={values.retentionAliquotPercentage}
      disabled={values.isSimples && !values.isAliquotHighlighted}
      onChange={(_event, _maskedValue, floatValue) => {
        setFieldValue("retentionAliquotPercentage", floatValue);
      }}
    />
  );
};

export const ISSResultCard = ({ value, componentRef }) => {
  return (
    <SimulatorResultCard
      componentRef={componentRef}
      title="Valor da retenção do ISS:"
      value={value}
      description="O valor da retenção do ISS é aquele valor que deve ser abatido do valor a ser pago para o contratado."
    />
  );
};

export const IsAliquotHighlighted = ({onChange}) => {
  const { values, setFieldValue } = useFormikContext();
  return (
    <RadioQuestion
    text="A alíquota está destacada na nota fiscal?"
    excerpt="A alíquota de retenção do ISS do optante pelo Simples Nacional deve ser destacada por ele na nota fiscal da prestação do serviço. A responsabilidade pela informação é exclusivamente do prestador (art. 21, §4º, VI, da LC 123/2006)"
      onChange={(value) => {
        setFieldValue("isAliquotHighlighted", value === "true");
        //onChange();
      }}
  
      value={values.isAliquotHighlighted.toString()}
    />
  )
}
