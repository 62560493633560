import Axios from "axios"
const consultarCNPJ = require('consultar-cnpj')


export default async (cnpj) => {
    let data = await consultarCNPJ(cnpj);
    if(data == null){
        data = await Axios.get(`https://receitaws.com.br/v1/cnpj/${cnpj}`);
    }
    return data;
}

/*var model = {
    "cnpj_raiz": "27865757",
    "razao_social": "GLOBO COMUNICACAO E PARTICIPACOES S/A",
    "capital_social": "6983568523.86",
    "responsavel_federativo": "",
    "atualizado_em": "2021-07-20T05:41:44.884Z",
    "porte": {
      "id": "05",
      "descricao": "Demais"
    },
    "natureza_juridica": {
      "id": "2054",
      "descricao": "Sociedade Anônima Fechada"
    },
    "qualificacao_do_responsavel": {
      "id": 10,
      "descricao": "Diretor "
    },
    "socios": [
      {
        "cpf_cnpj_socio": "***486498**",
        "nome": "RAYMUNDO COSTA PINTO BARROS",
        "tipo": "Pessoa Física",
        "data_entrada": "2021-03-15",
        "cpf_representante_legal": "***000000**",
        "nome_representante": null,
        "faixa_etaria": "51 a 60 anos",
        "atualizado_em": "2021-07-21T06:55:45.553Z",
        "pais_id": "1058",
        "qualificacao_socio": {
          "id": 10,
          "descricao": "Diretor "
        },
        "qualificacao_representante": null
      },
      {
        "cpf_cnpj_socio": "***720677**",
        "nome": "MANZAR GOMES FERES",
        "tipo": "Pessoa Física",
        "data_entrada": "2021-04-26",
        "cpf_representante_legal": "***000000**",
        "nome_representante": null,
        "faixa_etaria": "51 a 60 anos",
        "atualizado_em": "2021-07-21T06:55:45.553Z",
        "pais_id": "1058",
        "qualificacao_socio": {
          "id": 10,
          "descricao": "Diretor "
        },
        "qualificacao_representante": null
      },
      {
        "cpf_cnpj_socio": "***617850**",
        "nome": "CARLOS HENRIQUE SCHRODER",
        "tipo": "Pessoa Física",
        "data_entrada": "2014-12-12",
        "cpf_representante_legal": "***000000**",
        "nome_representante": null,
        "faixa_etaria": "61 a 70 anos",
        "atualizado_em": "2021-07-21T06:55:45.553Z",
        "pais_id": "1058",
        "qualificacao_socio": {
          "id": 10,
          "descricao": "Diretor "
        },
        "qualificacao_representante": null
      },
      {
        "cpf_cnpj_socio": "***632567**",
        "nome": "JORGE LUIZ DE BARROS NOBREGA",
        "tipo": "Pessoa Física",
        "data_entrada": "2014-12-12",
        "cpf_representante_legal": "***000000**",
        "nome_representante": null,
        "faixa_etaria": "61 a 70 anos",
        "atualizado_em": "2021-07-21T06:55:45.553Z",
        "pais_id": "1058",
        "qualificacao_socio": {
          "id": 10,
          "descricao": "Diretor "
        },
        "qualificacao_representante": null
      },
      {
        "cpf_cnpj_socio": "***030727**",
        "nome": "MARCELO LUIS MENDES SOARES DA SILVA",
        "tipo": "Pessoa Física",
        "data_entrada": "2014-12-12",
        "cpf_representante_legal": "***000000**",
        "nome_representante": null,
        "faixa_etaria": "51 a 60 anos",
        "atualizado_em": "2021-07-21T06:55:45.553Z",
        "pais_id": "1058",
        "qualificacao_socio": {
          "id": 10,
          "descricao": "Diretor "
        },
        "qualificacao_representante": null
      },
      {
        "cpf_cnpj_socio": "***048947**",
        "nome": "PAULO DAUDT MARINHO",
        "tipo": "Pessoa Física",
        "data_entrada": "2020-01-16",
        "cpf_representante_legal": "***000000**",
        "nome_representante": null,
        "faixa_etaria": "41 a 50 anos",
        "atualizado_em": "2021-07-21T06:55:45.553Z",
        "pais_id": "1058",
        "qualificacao_socio": {
          "id": 10,
          "descricao": "Diretor "
        },
        "qualificacao_representante": null
      },
      {
        "cpf_cnpj_socio": "***223047**",
        "nome": "ERICK DE MIRANDA BRETAS",
        "tipo": "Pessoa Física",
        "data_entrada": "2020-01-16",
        "cpf_representante_legal": "***000000**",
        "nome_representante": null,
        "faixa_etaria": "41 a 50 anos",
        "atualizado_em": "2021-07-21T06:55:45.553Z",
        "pais_id": "1058",
        "qualificacao_socio": {
          "id": 10,
          "descricao": "Diretor "
        },
        "qualificacao_representante": null
      },
      {
        "cpf_cnpj_socio": "***050187**",
        "nome": "PEDRO BORGES GARCIA",
        "tipo": "Pessoa Física",
        "data_entrada": "2020-01-16",
        "cpf_representante_legal": "***000000**",
        "nome_representante": null,
        "faixa_etaria": "51 a 60 anos",
        "atualizado_em": "2021-07-21T06:55:45.553Z",
        "pais_id": "1058",
        "qualificacao_socio": {
          "id": 10,
          "descricao": "Diretor "
        },
        "qualificacao_representante": null
      },
      {
        "cpf_cnpj_socio": "***649137**",
        "nome": "MANUEL LUIS ROQUETE CAMPELO BELMAR DA COSTA",
        "tipo": "Pessoa Física",
        "data_entrada": "2020-01-16",
        "cpf_representante_legal": "***000000**",
        "nome_representante": null,
        "faixa_etaria": "51 a 60 anos",
        "atualizado_em": "2021-07-21T06:55:45.553Z",
        "pais_id": "1058",
        "qualificacao_socio": {
          "id": 10,
          "descricao": "Diretor "
        },
        "qualificacao_representante": null
      },
      {
        "cpf_cnpj_socio": "***834960**",
        "nome": "CLAUDIA FALCAO DA MOTTA",
        "tipo": "Pessoa Física",
        "data_entrada": "2020-01-16",
        "cpf_representante_legal": "***000000**",
        "nome_representante": null,
        "faixa_etaria": "51 a 60 anos",
        "atualizado_em": "2021-07-21T06:55:45.553Z",
        "pais_id": "1058",
        "qualificacao_socio": {
          "id": 10,
          "descricao": "Diretor "
        },
        "qualificacao_representante": null
      },
      {
        "cpf_cnpj_socio": "***492757**",
        "nome": "RICARDO CAPILLE WADDINGTON",
        "tipo": "Pessoa Física",
        "data_entrada": "2021-06-24",
        "cpf_representante_legal": "***000000**",
        "nome_representante": null,
        "faixa_etaria": "51 a 60 anos",
        "atualizado_em": "2021-07-21T06:55:45.553Z",
        "pais_id": "1058",
        "qualificacao_socio": {
          "id": 10,
          "descricao": "Diretor "
        },
        "qualificacao_representante": null
      }
    ],
    "simples": null,
    "estabelecimento": {
      "cnpj": "27865757000102",
      "atividades_secundarias": [
        {
          "id": "5911102",
          "secao": "J",
          "divisao": "59",
          "grupo": "59.1",
          "classe": "59.11-1",
          "subclasse": "5911-1/02",
          "descricao": "Produção de filmes para publicidade"
        },
        {
          "id": "5911199",
          "secao": "J",
          "divisao": "59",
          "grupo": "59.1",
          "classe": "59.11-1",
          "subclasse": "5911-1/99",
          "descricao": "Atividades de produção cinematográfica, de vídeos e de programas de televisão não especificadas anteriormente"
        },
        {
          "id": "5912002",
          "secao": "J",
          "divisao": "59",
          "grupo": "59.1",
          "classe": "59.12-0",
          "subclasse": "5912-0/02",
          "descricao": "Serviços de mixagem sonora em produção audiovisual"
        },
        {
          "id": "5913800",
          "secao": "J",
          "divisao": "59",
          "grupo": "59.1",
          "classe": "59.13-8",
          "subclasse": "5913-8/00",
          "descricao": "Distribuição cinematográfica, de vídeo e de programas de televisão"
        },
        {
          "id": "5920100",
          "secao": "J",
          "divisao": "59",
          "grupo": "59.2",
          "classe": "59.20-1",
          "subclasse": "5920-1/00",
          "descricao": "Atividades de gravação de som e de edição de música"
        },
        {
          "id": "6022501",
          "secao": "J",
          "divisao": "60",
          "grupo": "60.2",
          "classe": "60.22-5",
          "subclasse": "6022-5/01",
          "descricao": "Programadoras"
        },
        {
          "id": "6204000",
          "secao": "J",
          "divisao": "62",
          "grupo": "62.0",
          "classe": "62.04-0",
          "subclasse": "6204-0/00",
          "descricao": "Consultoria em tecnologia da informação"
        },
        {
          "id": "6319400",
          "secao": "J",
          "divisao": "63",
          "grupo": "63.1",
          "classe": "63.19-4",
          "subclasse": "6319-4/00",
          "descricao": "Portais, provedores de conteúdo e outros serviços de informação na Internet"
        },
        {
          "id": "6463800",
          "secao": "K",
          "divisao": "64",
          "grupo": "64.6",
          "classe": "64.63-8",
          "subclasse": "6463-8/00",
          "descricao": "Outras sociedades de participação, exceto holdings"
        },
        {
          "id": "7020400",
          "secao": "M",
          "divisao": "70",
          "grupo": "70.2",
          "classe": "70.20-4",
          "subclasse": "7020-4/00",
          "descricao": "Atividades de consultoria em gestão empresarial, exceto consultoria técnica específica"
        },
        {
          "id": "7490104",
          "secao": "M",
          "divisao": "74",
          "grupo": "74.9",
          "classe": "74.90-1",
          "subclasse": "7490-1/04",
          "descricao": "Atividades de intermediação e agenciamento de serviços e negócios em geral, exceto imobiliários"
        },
        {
          "id": "7739099",
          "secao": "N",
          "divisao": "77",
          "grupo": "77.3",
          "classe": "77.39-0",
          "subclasse": "7739-0/99",
          "descricao": "Aluguel de outras máquinas e equipamentos comerciais e industriais não especificados anteriormente, sem operador"
        },
        {
          "id": "7740300",
          "secao": "N",
          "divisao": "77",
          "grupo": "77.4",
          "classe": "77.40-3",
          "subclasse": "7740-3/00",
          "descricao": "Gestão de ativos intangíveis não financeiros"
        },
        {
          "id": "9001902",
          "secao": "R",
          "divisao": "90",
          "grupo": "90.0",
          "classe": "90.01-9",
          "subclasse": "9001-9/02",
          "descricao": "Produção musical"
        },
        {
          "id": "9319101",
          "secao": "R",
          "divisao": "93",
          "grupo": "93.1",
          "classe": "93.19-1",
          "subclasse": "9319-1/01",
          "descricao": "Produção e promoção de eventos esportivos"
        }
      ],
      "cnpj_raiz": "27865757",
      "cnpj_ordem": "0001",
      "cnpj_digito_verificador": "02",
      "tipo": "Matriz",
      "nome_fantasia": "TV/REDE/CANAIS/G2C+GLOBO SOMLIVRE GLOBO.COM GLOBOPLAY",
      "situacao_cadastral": "Ativa",
      "data_situacao_cadastral": "2005-11-03",
      "data_inicio_atividade": "1986-01-31",
      "nome_cidade_exterior": null,
      "tipo_logradouro": "RUA",
      "logradouro": "LOPES QUINTAS",
      "numero": "303",
      "complemento": null,
      "bairro": "JARDIM BOTANICO",
      "cep": "22460901",
      "ddd1": "21",
      "telefone1": "21554551",
      "ddd2": "21",
      "telefone2": "21554552",
      "ddd_fax": "21",
      "fax": "25408773",
      "email": null,
      "situacao_especial": null,
      "data_situacao_especial": null,
      "atividade_principal": {
        "id": "6021700",
        "secao": "J",
        "divisao": "60",
        "grupo": "60.2",
        "classe": "60.21-7",
        "subclasse": "6021-7/00",
        "descricao": "Atividades de televisão aberta"
      },
      "pais": {
        "id": "1058",
        "iso2": "BR",
        "iso3": "BRA",
        "nome": "Brasil",
        "comex_id": "105"
      },
      "estado": {
        "id": 19,
        "nome": "Rio de Janeiro",
        "sigla": "RJ",
        "ibge_id": 33
      },
      "cidade": {
        "id": 3243,
        "nome": "Rio de Janeiro",
        "ibge_id": 3304557,
        "siafi_id": "6001"
      },
      "motivo_situacao_cadastral": null,
      "inscricoes_estaduais": [
        {
          "inscricao_estadual": "84295760",
          "ativo": true,
          "atualizado_em": "2021-08-13T15:11:08.241Z",
          "estado": {
            "id": 19,
            "nome": "Rio de Janeiro",
            "sigla": "RJ",
            "ibge_id": 33
          }
        },
        {
          "inscricao_estadual": "84347353",
          "ativo": false,
          "atualizado_em": "2021-08-13T15:11:08.241Z",
          "estado": {
            "id": 19,
            "nome": "Rio de Janeiro",
            "sigla": "RJ",
            "ibge_id": 33
          }
        },
        {
          "inscricao_estadual": "113717433112",
          "ativo": false,
          "atualizado_em": "2021-08-18T20:40:56.654Z",
          "estado": {
            "id": 26,
            "nome": "São Paulo",
            "sigla": "SP",
            "ibge_id": 35
          }
        }
      ]
    }
  }*/