import React, { useContext } from "react";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import { getRealTimeZoneDate } from "~/presentation/utils/getTimeZoneData";
import HeaderForm from "../../components/HeaderForm/HeaderForm";
import ExplanatoryVideoForm from "./Form";

function CreateEdit({ data, onCloseForm, refreshData, explanatoryVideoUseCases }) {
  const { openBackdrop } = useContext(BackdropContext);
  const { setFeedbackSnackbar } = useContext(SnackbarContext);
  const isCreate = !data;

  const getInitialValues = () => {
    if (data) {
      return { ...data, publishDate: getRealTimeZoneDate(data?.publishDate) };
    }
    return {
      id: 0,
      title: "",
      publishDate: new Date(),
      text: "",
    };
  };

  const handleSubmit = async (values) => {
    openBackdrop(true, `${isCreate ? "Criando" : "Editando"} pergunta`);
    try {
      if (isCreate) {
        await explanatoryVideoUseCases.create(values);
      } else {
        await explanatoryVideoUseCases.update(values);
      }
      setFeedbackSnackbar({
        isOpen: true,
        message: `Pergunta ${isCreate ? "criada" : "editada"}`,
        type: "success",
      });
      onCloseForm();
      await refreshData();
    } catch (error) {
      setFeedbackSnackbar({
        isOpen: true,
        message: "Erro. " + error?.response?.data,
        type: "error",
      });
    } finally {
      openBackdrop(false);
    }
  };

  return (
    <>
      <HeaderForm title={`${isCreate ? "Criar" : "Editar"} Pergunta`} backFnc={onCloseForm} />
      <ExplanatoryVideoForm initialValues={getInitialValues()} handleSubmit={handleSubmit} />
    </>
  );
}

export default CreateEdit;
