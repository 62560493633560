import React, { useContext, useEffect, useState } from "react";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import ServiceForm from "./Form";
import HeaderForm from "../../components/HeaderForm/HeaderForm";

function CreateEdit({ data, onCloseForm, refreshData, getSelectOptions, serviceUseCases, cnaes }) {
  const { openBackdrop } = useContext(BackdropContext);
  const { setFeedbackSnackbar } = useContext(SnackbarContext);
  const isCreate = !data;
  const [initialValues, setInitialValues] = useState();

  useEffect(() => {
    if (cnaes.length === 0) {
      getSelectOptions();
    }
  }, []);

  useEffect(() => {
    const fetch = async () => {
      openBackdrop(true, "Carregando informação");
      const result = await serviceUseCases.getServiceCodeById(data.id);
      setInitialValues(result);
      openBackdrop(false);
    };
    if (data?.id) {
      fetch();
    } else {
      setInitialValues({
        id: 0,
        description: "",
        isDefaultISS: false,
        code: "",
        cnaes: [],
      });
    }
  }, [data]);

  const handleSubmit = async (values) => {
    openBackdrop(true, `${isCreate ? "Criando" : "Editando"} serviço`);
    try {
      if (isCreate) {
        await serviceUseCases.createService(values);
      } else {
        await serviceUseCases.updateServiceCode(values);
      }
      onCloseForm();
      await refreshData();
      setFeedbackSnackbar({
        isOpen: true,
        message: `Serviço ${isCreate ? "criado" : "atualizado"} com sucesso!`,
        type: "success",
      });
    } catch (error) {
      setFeedbackSnackbar({
        isOpen: true,
        message: "Erro. " + error?.response?.data,
        type: "error",
      });
    } finally {
      openBackdrop(false);
    }
  };

  return (
    <>
      <HeaderForm title={`${isCreate ? "Cadastrar" : "Editar"} serviço`} backFnc={onCloseForm} />
      {initialValues && (
        <ServiceForm initialValues={initialValues} handleSubmit={handleSubmit} cnaes={cnaes} />
      )}
    </>
  );
}

export default CreateEdit;
