import React, { useContext, useEffect, useRef, useState } from "react";
import AppContext from "~/presentation/AppContext";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import { DialogContext } from "~/presentation/providers/DialogProvider";
import parse from "html-react-parser";
import PageListHeader from "../components/PageListHeader";
import Table from "~/presentation/components/Table";
import TableActions from "~/presentation/components/TableActions";
import { Container } from "@mui/material";
import CreateEdit from "./CreateEdit/CreateEdit";
import triggerInputChange from "~/presentation/hooks/triggerInputChange";

const limitCaracteres = (value) => {
  if (value?.length > 50) {
    return value.substring(0, 50) + "...";
  }
  return value;
};

const Annotation = ({ annotationUseCases, cnaesUseCases, termUseCases }) => {
  const context = useContext(AppContext);
  const { openBackdrop } = useContext(BackdropContext);
  const { setFeedbackSnackbar } = useContext(SnackbarContext);
  const { openDialogBox } = useContext(DialogContext);

  //Data
  const [notes, setNotes] = useState([]);

  //Search
  const hiddenInputRef = useRef();
  const searchFieldRef = useRef();

  //select
  const [cnaes, setCnaes] = useState([]);
  const [terms, setTerms] = useState([]);

  //Abrir forms criar / editar
  const [openForm, setOpenForm] = useState(false);
  const [formProps, setFormProps] = useState(false);

  const handleOpenForm = (data = null) => {
    setOpenForm(true);
    setFormProps(data);
  };

  const handleCloseForm = () => {
    setOpenForm(false);
    setFormProps(null);
  };

  const columns = [
    {
      name: "name",
      label: "Nome",
    },
    {
      name: "text",
      label: "Texto",
      options: {
        filter: false,
        customBodyRender: (value) => {
          const text = parse(value);
          const textParsed = text?.props?.children;
          return textParsed ? limitCaracteres(textParsed) : null;
        },
      },
    },
    { name: "actions", label: "Ações", options: { sortable: "false" } },
  ];

  const fetchData = async () => {
    openBackdrop(true, "Carregando dados");
    const { data = [] } = await annotationUseCases.getAnnotations();
    const userIsSuperAdmin = context.haveSuperAdminAccess();
    data.forEach((answer) => {
      if (userIsSuperAdmin) {
        answer.actions = (
          <TableActions
            handleDelete={() => deleteDialog(answer.id)}
            handleEdit={() => handleOpenForm(answer)}
          />
        );
      } else {
        answer.actions = <TableActions handleEdit={() => handleOpenForm(answer)} />;
      }
    });
    setNotes(data);
    openBackdrop(false);
  };

  const handleDelete = async (id) => {
    openBackdrop(true, "Excluindo resposta");
    try {
      await annotationUseCases.deleteAnnotation(id);
      setNotes((prev) => prev.filter((item) => item.id !== id));
      cleanSearch();
    } catch (error) {
      setFeedbackSnackbar({
        isOpen: true,
        message: "Erro ao excluir anotação",
        type: "error",
      });
    }
    openBackdrop(false);
  };

  const deleteDialog = (id) => {
    openDialogBox({
      message: `Deseja mesmo excluir esta resposta?`,
      callback: () => handleDelete(id),
    });
  };

  const handleSearch = (value) => triggerInputChange(hiddenInputRef.current, value);

  const cleanSearch = () => {
    triggerInputChange(hiddenInputRef.current);
    triggerInputChange(searchFieldRef.current);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchSelectOptions = async () => {
    try {
      openBackdrop(true, "Carregando dados");
      const [cnaesListResponse, termsListResponse] = await Promise.all([
        cnaesUseCases.listCnaes(),
        termUseCases.getAll(),
      ]);
      setCnaes(cnaesListResponse.data);
      setTerms(termsListResponse);
    } catch (error) {
      setFeedbackSnackbar({
        isOpen: true,
        message: "Anotação não encontrada!",
        type: "warning",
      });
    } finally {
      openBackdrop(false);
    }
  };

  return (
    <Container maxWidth="100%">
      {openForm ? (
        <CreateEdit
          data={formProps}
          onCloseForm={handleCloseForm}
          refreshData={fetchData}
          annotationUseCases={annotationUseCases}
          fetchSelectOptions={fetchSelectOptions}
          cnaes={cnaes}
          terms={terms}
        />
      ) : (
        <>
          <PageListHeader
            title="Anotações"
            titleAccess="Nova resposta"
            handleAdd={() => handleOpenForm()}
            handleSearch={handleSearch}
            searchFieldRef={searchFieldRef}
          />
          <Table columns={columns} data={notes} searchRef={hiddenInputRef} />
        </>
      )}
    </Container>
  );
};
export default Annotation;
