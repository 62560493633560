import React from "react";

import {ImageInputWrapper} from "./styles";

const ImageInput = ({title, imageFile, onChange, uploadedImageRef}) => {
  return (
    <ImageInputWrapper htmlFor="image" image={imageFile}>
      <div>{title}</div>
      <input
        name="image"
        type="file"
        accept={"image/*"}
        multiple={false}
        onChange={onChange}
      />
      <img ref={uploadedImageRef} src={imageFile} />
    </ImageInputWrapper>
  );
};

export default ImageInput;
