import React, { useContext } from "react";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import HeaderForm from "../../components/HeaderForm/HeaderForm";
import TypeAnswerForm from "./Form";

function CreateEdit({ data, onCloseForm, refreshData, typesAnswerUseCases }) {
  const { openBackdrop } = useContext(BackdropContext);
  const { setFeedbackSnackbar } = useContext(SnackbarContext);
  const isCreate = !data;

  const getInitialValues = () => {
    if (data) {
      return data;
    }
    return { id: 0, title: "" };
  };

  const handleSubmit = async (values) => {
    openBackdrop(true, `${isCreate ? "Criando" : "Editando"} tipo de resposta`);
    try {
      if (isCreate) {
        await typesAnswerUseCases.createTypeAnswer(values);
      } else {
        await typesAnswerUseCases.updateTypeAnswer(values);
      }
      setFeedbackSnackbar({
        isOpen: true,
        message: `Tipo de resposta ${isCreate ? "criada" : "editada"}!`,
        type: "success",
      });
      onCloseForm();
      await refreshData();
    } catch (err) {
      openBackdrop(false);
      setFeedbackSnackbar({
        isOpen: true,
        message: "Erro. " + err?.response?.data,
        type: "error",
      });
    }
    openBackdrop(false);
  };

  return (
    <>
      <HeaderForm
        title={`${isCreate ? "Criar" : "Editar"} Tipo de Resposta`}
        backFnc={onCloseForm}
      />
      <TypeAnswerForm initialValues={getInitialValues()} handleSubmit={handleSubmit} />
    </>
  );
}

export default CreateEdit;
