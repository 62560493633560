import React from "react";
import { makeAnnotationUseCases } from "~/main/factories/use-cases/annotation";
import { makeCnaesUseCases } from "~/main/factories/use-cases/cnaes";
import { makeTermUseCases } from "~/main/factories/use-cases/term";
import Annotation from "~/presentation/views/Admin/Annotation/Annotation";

export const makeAnnotationTable = (params) => (
  <Annotation
    {...params}
    cnaesUseCases={makeCnaesUseCases()}
    annotationUseCases={makeAnnotationUseCases()}
    termUseCases={makeTermUseCases()}
  />
);
