import {Formik} from "formik";
import React, {useContext, useState} from "react";
import { Card } from "~/presentation/views/GTFacil/components/Card";
import { Container, Checkbox, CheckboxesWrapper, Title, Warning, Button, Form } from "./styles";
import {Box, FormControlLabel} from "@mui/material";
import {InfoIcon} from "~/presentation/views/GTFacil/Search/components/SearchForm/styles";
import {SimulatorContext} from "~/presentation/views/GTFacil/Simulator/context/simulator-context";

export const GenerateSimulatorReportDialog = ({ reportData, onSubmit, setOpenGenerateReportDialog }) => {

  const { tabsData } = useContext(SimulatorContext);

  const [submitError, setSubmitError] = useState();

  const reportOptions = tabsData.reduce((acc, item) => {
    acc[item.title] = false;
    return acc;
  }, {});

  const initialValues = Object.keys(reportOptions).reduce((acc, item) => {
    acc[item] = reportData.some((reportItem) => {
      if(reportItem?.title) {
        if(reportItem.title === "IRRF" && item === "IRPJ") reportItem.title = "IRPJ";
        return reportItem.title === item;
      }
      return false;
    });
    return acc;
  }, {});

  const isDisabled = (values) => !Object.keys(values).some((item) => values[item]) === true;

  return (
    <Container>
      <Card
        title="Gerar relatório"
        loading={!reportData}
        border={false}
        handleClose={() => setOpenGenerateReportDialog(false)}
      >
        {submitError ? (
          <Title>{submitError}</Title>
        ) : (
          <>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <InfoIcon/>
              <Title>Selecione os tributos para a geração do PDF</Title>
            </Box>
            <Formik
              initialValues={initialValues}
              onSubmit={async (values, action) => {
                action.setSubmitting(true);
                try {
                  const filterdReportData = reportData.filter((item) => values[item.title]).map((item) => {
                    if(item.title === "IRPJ") item.title = "IRRF";
                    return item;
                  });
                  await onSubmit(filterdReportData);
                  setOpenGenerateReportDialog(false);
                } catch (err) {
                  setSubmitError(err.message);
                } finally {
                  action.setSubmitting(false);
                }
              }}
            >
              {({ setFieldValue, values }) => (
                <Form>
                  <CheckboxesWrapper>
                    {Object.keys(reportOptions).length ? (
                      Object.keys(reportOptions).map((item) => (
                        <FormControlLabel
                          disabled={!reportData.some((reportItem) => reportItem?.title && reportItem?.title === item)}
                          key={item}
                          control={
                            <Checkbox
                              name={item}
                              checked={values[item]}
                              onChange={() => {
                                setFieldValue(item, !values[item]);
                              }}
                            />
                          }
                          label={item}
                        />
                      ))
                    ) : (
                      <Warning>Não há dados para gerar o relatório</Warning>
                    )}
                  </CheckboxesWrapper>
                  <Button disabled={isDisabled(values)} type="submit">
                  {
                    isDisabled(values)
                      ? "Selecione um Tributo"
                      : "Gerar Relatório"
                  }
                  </Button>
                </Form>
              )}
            </Formik>
          </>
        )}
      </Card>
    </Container>
  );
};
