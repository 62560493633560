import React from "react";
import { Switch, Redirect, Route } from "react-router-dom";
// layout
import DashboardLayout from "~/presentation/layouts/DashboardLayout";
// factories
import { makeUserTable } from "~/main/factories/pages/admin/user";
import { makeProfile } from "~/main/factories/pages/admin/profile/makeProfile";
import { makeCompanyTable } from "~/main/factories/pages/admin/company";

import { makeContentTable } from "~/main/factories/pages/admin/content";
import { makeBlogTable } from "~/main/factories/pages/admin/blog";
import { makeBlogCategoryTable } from "~/main/factories/pages/admin/blog-category";
import {
  makeBlogCommentList,
  makeBlogCommentReply,
} from "~/main/factories/pages/admin/blog-comment";
import { makeSurveyTable } from "~/main/factories/pages/admin/survey";
import {
  makeGTFacilAnswers,
  makeGTFacilCNAE,
  makeGTFacilFreeSearch,
} from "~/main/factories/pages/admin/gt-facil";
import {
  makeCreateDialog,
  makeDialogTable,
  makeEditDialog,
} from "~/main/factories/pages/admin/gt-facil-dialog";
import {
  makeISSAnswers,
  makeISSCities,
  makeISSStandardAnswers,
  makeUpdateCities,
} from "~/main/factories/pages/admin/iss";
import { makeValueVariableTable } from "~/main/factories/pages/admin/value-variable";
import {
  makeReplyTaxInformation,
  makeTaxInformationList,
} from "~/main/factories/pages/admin/tax-information";
import { makeAdminHome } from "~/main/factories/pages/admin/home/makeAdminHome";
import { makeExplanatoryVideoTable } from "~/main/factories/pages/admin/explanatory-video";
import { makePlanList } from "~/main/factories/pages/admin/plan";
import { makeServiceList } from "~/main/factories/pages/admin/service-code";
import { makeCnaeList } from "~/main/factories/pages/admin/cnae";
import { makeAnswerTable } from "~/main/factories/pages/admin/answers";
import { makeTypesAnswerTable } from "~/main/factories/pages/admin/types-answer";
import { makeItemAnswerTable } from "~/main/factories/pages/admin/item-answers";
import { makeIssAnswerTable } from "~/main/factories/pages/admin/iss-answers";
import { makeAnnotationTable } from "~/main/factories/pages/admin/annotation";
import makeSubscriptionTable from "~/main/factories/pages/admin/subscription/makeSubscriptionTable";
import { makeListTerms } from "~/main/factories/pages/admin/search-term";
import { makeLegislationCategoryTable } from "~/main/factories/pages/admin/legislation-category";
import { makeStats } from "~/main/factories/pages/admin/stats/makeStats";

const AdminRoutes = ({ match: { url } }) => {
  return (
    <DashboardLayout>
      <Switch>
        <Route exact path={`${url}`} component={makeAdminHome} />
        {/* empresa */}
        <Route exact path={`${url}/empresa`} component={makeCompanyTable} />
        {/* plans */}
        <Route exact path={`${url}/plano`} component={makePlanList} />
        {/* usuario */}
        <Route exact path={`${url}/usuario`} component={makeUserTable} />
        {/* meu perfil */}
        <Route exact path={`${url}/meu-perfil`} component={makeProfile} />
        {/* estatística */}
        <Route
          exact
          path={`${url}/estatistica`}
          component={() => <h1>Mostra estatísticas do site</h1>}
        />
        <Route exact path={`${url}/estatistica/contagem-de-visualizacoes`} component={makeStats} />
        {/* conteúdo */}
        <Route exact path={`${url}/conteudo`} component={makeContentTable} />
        {/* blog */}
        <Route exact path={`${url}/blog`} component={makeBlogTable} />
        {/* blog/categoria */}
        <Route exact path={`${url}/blog/categoria`} component={makeBlogCategoryTable} />
        {/* comentarios */}
        <Route exact path={`${url}/comentarios`} component={makeBlogCommentList} />
        <Route exact path={`${url}/comentarios/responder/:id`} component={makeBlogCommentReply} />
        {/* enquete */}
        <Route exact path={`${url}/enquetes`} component={makeSurveyTable} />
        {/* GT fácil */}
        <Route
          exact
          path={`${url}/gt-facil/importar/pesquisa-livre`}
          component={makeGTFacilFreeSearch}
        />
        <Route exact path={`${url}/gt-facil/importar/cnae`} component={makeGTFacilCNAE} />
        <Route exact path={`${url}/gt-facil/importar/respostas`} component={makeGTFacilAnswers} />
        {/* GT fácil/diálogo */}
        <Route exact path={`${url}/gt-facil/dialogo`} component={makeDialogTable} />
        <Route exact path={`${url}/gt-facil/dialogo/cadastrar`} component={makeCreateDialog} />
        <Route exact path={`${url}/gt-facil/dialogo/editar/:id`} component={makeEditDialog} />
        {/* Consulta ISS */}
        <Route
          exact
          path={`${url}/consulta-iss/importar/respostas-cadastradas`}
          component={makeISSAnswers}
        />
        <Route
          exact
          path={`${url}/consulta-iss/importar/respostas-padrao`}
          component={makeISSStandardAnswers}
        />
        <Route exact path={`${url}/consulta-iss/importar/cidade`} component={makeISSCities} />
        <Route
          exact
          path={`${url}/consulta-iss/atualizar-municipios`}
          component={makeUpdateCities}
        />
        {/* variáveis de valor */}
        <Route exact path={`${url}/variaveis-de-valor`} component={makeValueVariableTable} />
        {/* informações fiscais */}
        <Route exact path={`${url}/informacoes-fiscais`} component={makeTaxInformationList} />
        <Route
          exact
          path={`${url}/informacoes-fiscais/responder/:id`}
          component={makeReplyTaxInformation}
        />
        {/* vídeos explicativos */}
        <Route exact path={`${url}/ajuda`} component={makeExplanatoryVideoTable} />
        {/* Serviços */}
        <Route exact path={`${url}/servicos`} component={makeServiceList} />
        {/* Cnae */}
        <Route exact path={`${url}/cnaes`} component={makeCnaeList} />
        {/* Respostas-ISS*/}
        <Route exact path={`${url}/respostas/respostas-iss`} component={makeIssAnswerTable} />
        {/* Respostas */}
        <Route exact path={`${url}/respostas`} component={makeAnswerTable} />
        {/* Tipos de resposta */}
        <Route exact path={`${url}/tipos-de-resposta`} component={makeTypesAnswerTable} />
        {/* Itens de resposta */}
        <Route exact path={`${url}/itens-de-resposta`} component={makeItemAnswerTable} />
        {/* Anotaçôes */}
        <Route exact path={`${url}/anotacoes`} component={makeAnnotationTable} />
        {/* Inscrições */}
        <Route exact path={`${url}/inscricoes`} component={makeSubscriptionTable} />
        {/* SearchTerm */}
        <Route exact path={`${url}/termos-de-pesquisa`} component={makeListTerms} />
        {/*categoria de legislação*/}
        <Route
          exact
          path={`${url}/categoria-legislacao`}
          component={makeLegislationCategoryTable}
        />
        <Redirect to={url} />
      </Switch>
    </DashboardLayout>
  );
};

export default AdminRoutes;
