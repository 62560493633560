import React, { useContext } from "react";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import HeaderForm from "../../components/HeaderForm/HeaderForm";
import Form from "./Form";

function CreateEdit({ data, onCloseForm, refreshData, valueVariableUseCases }) {
  const { openBackdrop } = useContext(BackdropContext);
  const { setFeedbackSnackbar } = useContext(SnackbarContext);
  const isCreate = !data;

  const getInitialValues = () => {
    if (data) {
      return data;
    }
    return {
      id: 0,
      code: "",
      value: null,
      name: "",
    };
  };

  const handleSubmit = async (values) => {
    openBackdrop(true, `${isCreate ? "Criando" : "Editando"} variável de valor`);
    try {
      if (isCreate) {
        await valueVariableUseCases.create(values);
      } else {
        await valueVariableUseCases.update(values);
      }
      setFeedbackSnackbar({
        isOpen: true,
        message: `Variável de valor ${isCreate ? "criada" : "editada"}!`,
        type: "success",
      });
      onCloseForm();
      await refreshData();
    } catch (error) {
      setFeedbackSnackbar({
        isOpen: true,
        message: "Erro. " + error?.response?.data,
        type: "error",
      });
    } finally {
      openBackdrop(false);
    }
  };

  return (
    <>
      <HeaderForm
        title={`${isCreate ? "Criar" : "Editar"} Variável de Valor`}
        backFnc={onCloseForm}
      />
      <Form initialValues={getInitialValues()} handleSubmit={handleSubmit} />
    </>
  );
}

export default CreateEdit;
