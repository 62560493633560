import { apiURL } from "../../usecases/api";
export class ApiExplanatoryVideoCategoryUseCases {
  constructor(httpClient) {
    this.httpClient = httpClient;
  }

  async create(data) {
    try {
      await this.httpClient.request({
        url: `${apiURL}/ExplanatoryVideoCategory`,
        method: "POST",
        data,
      });
    } catch (error) {
      return error;
    }
  }

  async get() {
    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/ExplanatoryVideoCategory`,
        method: "GET",
      });
      return response.data;
    } catch (err) {
      console.log(err.response);
      return err;
    }
  }

  async getById(id) {
    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/ExplanatoryVideoCategory/${id}`,
        method: "GET",
      });
      return response.data;
    } catch (err) {
      console.log(err.response);
    }
  }

  async update(data) {
    await this.httpClient.request({
      url: `${apiURL}/ExplanatoryVideoCategory/`,
      method: "PUT",
      data,
    });
  }

  async delete(id) {
    await this.httpClient.request({
      url: `${apiURL}/ExplanatoryVideoCategory/${id}`,
      method: "DELETE",
    });
  }
}
