import React, { useContext, useEffect } from "react";
import { dataToSelectMapper } from "~/presentation/functions/dataToSelectMapper";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import ItemAnswerForm from "./Form";
import HeaderForm from "../../components/HeaderForm/HeaderForm";

function CreateEdit({
  data,
  onCloseForm,
  refreshData,
  itemAnswerUseCases,
  fetchOptionsData,
  typesAnswer,
}) {
  const { openBackdrop } = useContext(BackdropContext);
  const { setFeedbackSnackbar } = useContext(SnackbarContext);
  const isCreate = !data;

  useEffect(() => {
    if (typesAnswer.length === 0) {
      fetchOptionsData();
    }
  }, []);

  const getInitialValues = () => {
    if (data) {
      return {
        id: data.id,
        title: data.title,
        text: data.text,
        gtAnswerItemType: dataToSelectMapper([data.gtAnswerItemType])[0],
      };
    }
    return {
      id: 0,
      title: "",
      text: "",
      gtAnswerItemType: {},
    };
  };

  const handleSubmit = async (values) => {
    openBackdrop(true, `${isCreate ? "Criando" : "Editando"} serviço`);
    try {
      if (isCreate) {
        await itemAnswerUseCases.create(values);
      } else {
        await itemAnswerUseCases.update(values);
      }
      setFeedbackSnackbar({
        isOpen: true,
        message: `Item de resposta ${isCreate ? "criada" : "atualizada"} com sucesso!`,
        type: "success",
      });
      onCloseForm();
      await refreshData();
    } catch (error) {
      setFeedbackSnackbar({
        isOpen: true,
        message: "Erro. " + error?.response?.data,
        type: "error",
      });
    } finally {
      openBackdrop(false);
    }
  };

  return (
    <>
      <HeaderForm
        title={`${isCreate ? "Criar" : "Editar"} item de resposta`}
        backFnc={onCloseForm}
      />
      <ItemAnswerForm
        initialValues={getInitialValues()}
        handleSubmit={handleSubmit}
        data={typesAnswer}
      />
    </>
  );
}

export default CreateEdit;
