import React from "react";
import { useEffect, useContext, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
// packages
import { Formik, Field } from "formik";
//components
import ErrorMessage from "~/presentation/components/ErrorMessage";
import PrimaryButton from "~/presentation/components/PrimaryButton";
// import TextInput from "~/presentation/components/TextInput";
import Select from "~/presentation/components/Select";
import { Container, Form } from "~/presentation/views/Admin/Service/CreateEdit/Form/styles";
import FormSubmitButtonsDock from "~/presentation/views/Admin/components/FormSubmitButtonsDock";
// schema
import { dataToSelectMapper } from "~/presentation/functions/dataToSelectMapper";
import validationSchema from "./validationSchema";
import { ItemModal } from "./ItemModal";
import { DialogComponent } from "~/presentation/components/DialogBox/styles";
import Table from "~/presentation/components/Table";
import TableActions from "~/presentation/components/TableActions";
// import triggerInputChange from "~/presentation/hooks/triggerInputChange";
import triggerInputChange from "~/presentation/hooks/triggerInputChange";
import { DialogContext } from "~/presentation/providers/DialogProvider";
import { FormControl, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import PageListHeader from "../../../components/PageListHeader";

const IssAnswerForm = ({ handleSubmit, initialValues, itemsAnswer, groups, handleDelete }) => {
  const history = useHistory();
  const { openDialogBox } = useContext(DialogContext);

  const hiddenInputRef = useRef();
  const searchFieldRef = useRef();

  const handleSearch = (value) => {
    triggerInputChange(hiddenInputRef.current, value);

    // Se a pesquisa estiver vazia, restaurar a lista completa de itens
    if (value.trim() === "") {
      setFilteredItems(items);
    } else {
      // Filtrar itens com base na pesquisa no título e texto
      const filteredItems = items.filter((item) => {
        const titleMatch = item.itemTypeName.toLowerCase().includes(value.toLowerCase());
        const textMatch = item.text.toLowerCase().includes(value.toLowerCase());
        return titleMatch || textMatch;
      });

      // Atualizar a lista de itens filtrados
      setFilteredItems(filteredItems);
    }
  };

  const [filteredItems, setFilteredItems] = useState([]);

  const newItem = {
    id: 0,
    isDefault: false,
    blockCode: "",
    group: "",
    servicesCode: "",
    itemTypeId: 0,
    itemTypeName: "",
    text: "",
  };

  const [item, setItem] = useState({
    open: false,
    content: newItem,
  });

  const [items, setItems] = useState(initialValues.items);

  useEffect(() => {
    initialValues.items.forEach((item) => {
      item.actions = (
        <TableActions
          handleEdit={() => handleEditTempItem(item)}
          handleDelete={() => deleteDialog(item.id)}
        />
      );
    });

    let formattedItems = initialValues.items;
    formattedItems = formattedItems.map((item) => {
      let groups = "";

      if (Array.isArray(item?.groups) && item?.groups?.length > 0) {
        for (let group of item?.groups) {
          groups += group.value + ";";
        }

        return {
          ...item,
          group: groups.trim(),
        };
      }

      return item;
    });

    setItems(formattedItems);
    setFilteredItems(formattedItems);
  }, [initialValues.items]);

  const handleEditTempItem = (item) => {
    setItem({
      open: true,
      content: item,
    });
  };

  const deleteDialog = (id) => {
    openDialogBox({
      message: `Deseja mesmo excluir este item de resposta?`,
      callback: () => handleDelete(id),
    });
  };

  const mappedGtAnswerItems = dataToSelectMapper(itemsAnswer);

  const cpomList = [
    {
      value: 0,
      label: "Nenhum",
    },
    {
      value: 1,
      label: "CPOM",
    },
    {
      value: 2,
      label: "CM",
    },
  ];

  const columns = [
    {
      name: "itemTypeName",
      label: "Tipo",
    },
    {
      name: "blockCode",
      label: "Bloco",
    },
    {
      name: "servicesCode",
      label: "Código de Serviço",
    },
    {
      name: "group",
      label: "Grupo",
    },
    {
      name: "actions",
      label: "Ações",
      options: { sortable: "false" },
    },
  ];

  const handleSaveItem = async (item) => {
    setItem({
      ...item,
      open: false,
    });
    const allItemsWithActions = item.id === 0 ? [...initialValues.items, item] : [...items];
    const index = allItemsWithActions.findIndex((element) => element.id === item.id);
    allItemsWithActions[index] = item;
    const allItems = allItemsWithActions.map((obj) => {
      const { actions, ...rest } = obj;
      return rest;
    });
    await handleSubmit({
      ...initialValues,
      isLegislation: initialValues.isLegislation === "true",
      isSpecificSubstituition: initialValues.isSpecificSubstituition === "true",
      isDispensedCPOM: initialValues.isDispensedCPOM === "true",
      isDispensedEROS: initialValues.isDispensedEROS === "true",
      items: allItems,
    });
    allItemsWithActions.forEach((item) => {
      item.actions = (
        <TableActions
          handleEdit={() => handleEditTempItem(item)}
          handleDelete={() => deleteDialog(item.id)}
        />
      );
    });
  };

  return (
    <Container className="scrollbar-white">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, action) => {
          action.setSubmitting(true);
          const submitValues = {
            id: values.id,
            isLegislation: values.isLegislation === "true" ? true : false,
            isDispensedEROS: values.isDispensedEROS === "true" ? true : false,
            isSpecificSubstituition: values.isSpecificSubstituition === "true" ? true : false,
            isDispensedCPOM: values.isDispensedCPOM === "true" ? true : false,
            cpom: values.cpom.value,
            cityId: values.cityId,
            cityName: values.cityName,
            items: items.map((element) => {
              let item = {
                id: element.id,
                isDefault: element.isDefault,
                blockCode: element.blockCode,
                groups: element.groups,
                servicesCode: element.servicesCode,
                itemTypeId: element.itemTypeId,
                itemTypeName: element.itemTypeName,
                text: element.text,
              };
              return item;
            }),
          };

          await handleSubmit(submitValues);
          history.push();
          action.setSubmitting(false);
        }}
      >
        {({ setFieldValue, values }) => (
          <Form>
            <h2>Resposta</h2>
            <div className="form-grid">
              <div className="col-1_5">
                <h3>Cidade selecionada: {values.cityName}</h3>
              </div>
              <div className="col-1_3">
                <h3>CPOM Dispensado</h3>
                <FormControl component="fieldset">
                  <RadioGroup
                    aria-label="isDispensedCPOM"
                    name="isDispensedCPOM"
                    value={values.isDispensedCPOM}
                    row
                    onChange={(event) => setFieldValue("isDispensedCPOM", event.target.value)}
                  >
                    <FormControlLabel value={"true"} control={<Radio />} label="Sim" />
                    <FormControlLabel value={"false"} control={<Radio />} label="Não" />
                  </RadioGroup>
                </FormControl>
              </div>
              <div className="col-1_5">
                <Field
                  name="cpom"
                  label="CPOM"
                  placeholder="CPOM"
                  component={Select}
                  options={cpomList}
                  onChange={(option) => setFieldValue("cpom", option)}
                  getOptionLabel={(option) => option.label}
                />
                <ErrorMessage name="cpom" />
              </div>
              <div className="col-1_2">
                <h3>Legislação</h3>
                <FormControl component="fieldset">
                  <RadioGroup
                    name="isLegislation"
                    aria-label="isLegislation"
                    row
                    value={values.isLegislation}
                    onChange={(event) => setFieldValue("isLegislation", event.target.value)}
                  >
                    <FormControlLabel value={"true"} control={<Radio />} label="Sim" />
                    <FormControlLabel value={"false"} control={<Radio />} label="Não" />
                  </RadioGroup>
                </FormControl>
              </div>
              <div className="col-2_3">
                <h3>ERO Dispensado</h3>
                <FormControl component="fieldset">
                  <RadioGroup
                    value={values.isDispensedEROS}
                    name="isDispensedEROS"
                    aria-label="isDispensedEROS"
                    row
                    onChange={(event) => setFieldValue("isDispensedEROS", event.target.value)}
                  >
                    <FormControlLabel value={"true"} control={<Radio />} label="Sim" />
                    <FormControlLabel value={"false"} control={<Radio />} label="Não" />
                  </RadioGroup>
                </FormControl>
              </div>
              <div className="col-3_4">
                <h3>Substituição específica</h3>
                <FormControl component="fieldset">
                  <RadioGroup
                    name="isSpecificSubstituition"
                    aria-label="isSpecificSubstituition"
                    row
                    value={values.isSpecificSubstituition}
                    onChange={(event) =>
                      setFieldValue("isSpecificSubstituition", event.target.value)
                    }
                  >
                    <FormControlLabel value={"true"} control={<Radio />} label="Sim" />
                    <FormControlLabel value={"false"} control={<Radio />} label="Não" />
                  </RadioGroup>
                </FormControl>
              </div>
            </div>
            {item.open && (
              <DialogComponent
                open={item.open}
                onClose={() => setItem({ open: false })}
                aria-labelledby="form-dialog-title"
              >
                <ItemModal
                  item={item}
                  itemAnswers={mappedGtAnswerItems}
                  handleSaveItem={handleSaveItem}
                  groups={groups}
                />
              </DialogComponent>
            )}
            <div>
              <PageListHeader
                title="Itens de resposta"
                titleAccess="Nova resposta"
                handleAdd={() => setItem({ open: true, content: newItem })}
                searchFieldRef={searchFieldRef}
                handleSearch={handleSearch}
              />
              <Table columns={columns} searchRef={hiddenInputRef} data={filteredItems} />
            </div>
            <FormSubmitButtonsDock>
              <PrimaryButton className="submit-btn" type="submit">
                Salvar
              </PrimaryButton>
            </FormSubmitButtonsDock>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default IssAnswerForm;
