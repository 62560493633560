import React, { useContext, useEffect } from "react";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import { getRealTimeZoneDate } from "~/presentation/utils/getTimeZoneData";
import HeaderForm from "../../components/HeaderForm/HeaderForm";
import BlogForm from "./Form";

function CreateEdit({
  data,
  onCloseForm,
  refreshData,
  blogUseCases,
  blogTags,
  blogCategories,
  getTags,
}) {
  const { openBackdrop } = useContext(BackdropContext);
  const { setFeedbackSnackbar } = useContext(SnackbarContext);
  const isCreate = !data;

  useEffect(() => {
    if (blogTags.length === 0) {
      getTags();
    }
  }, []);

  const getInitialValues = () => {
    if (data) {
      return {
        ...data,
        publishDate: getRealTimeZoneDate(data.publishDate),
      };
    }
    return {
      id: 0,
      categoryId: "",
      title: "",
      autor: "",
      image: "",
      videoUrl: "",
      addToMedia: false,
      publishDate: new Date(),
      tags: [],
      text: "",
      qsoTable: {
        inss1: [],
        inss2: [],
        inss3: [],
        irrf1: [],
        irrf2: [],
        csllPisCofins: [],
        iss1: [],
        iss2: [],
      },
      isDraft: false,
    };
  };

  const handleSubmit = async (values) => {
    openBackdrop(true, `${isCreate ? "Criando" : "Editando"} post do blog`);
    try {
      if (isCreate) {
        await blogUseCases.createBlogPost(values);
      } else {
        await blogUseCases.updateBlogPost(values);
      }
      setFeedbackSnackbar({
        isOpen: true,
        message: `Post ${isCreate ? "criado" : "editado"} com sucesso!`,
        type: "success",
      });
      onCloseForm();
      await refreshData();
    } catch (error) {
      setFeedbackSnackbar({
        isOpen: true,
        message: `Erro. ${error?.response?.data}`,
        type: "error",
      });
    }
    openBackdrop(false);
  };

  return (
    <>
      <HeaderForm title={`${isCreate ? "Criar" : "Editar"} Post`} backFnc={onCloseForm} />
      <BlogForm
        initialValues={getInitialValues()}
        handleSubmit={handleSubmit}
        categories={blogCategories}
        tags={blogTags}
      />
    </>
  );
}

export default CreateEdit;
